import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MetaTags } from "react-meta-tags";

import "./Aktualnosci.css";
import { formatDate } from "../../elements/formatDate";

import { ReactComponent as ClockIcon } from "../../images/icons/clock.svg";

export default class Aktualnosci extends Component {
  reverse = true;

  state = {
    aktualnosci: [],
    loaded: false,
    limit: 5,
    showFilters: false,
    startDate: "",
    endDate: "",
    phrase: "",
    reverse: true,
  };

  componentDidMount() {
    // Set input dates
    // document.getElementById("startDate").value = this.state.startDate;
    // document.getElementById("endDate").value = this.state.endDate;
    // Get aktualnosci
    let xmlhttp = new XMLHttpRequest();
    const THAT = this;
    xmlhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        // tempAktualnosci are in array, divided like JSON, but without quotes and braces
        // (JSON doesn't work because of new lines or hard enters or something)
        let tempAktualnosci = this.responseText
          // .slice(0, -17)
          .split(",RANDOMSTRING!@#,");
        // Convert tempAktualnosci to an array of objects (aktualnosci)
        let aktualnosci = [];
        for (let i = 0; i < Math.floor(tempAktualnosci.length / 10); i++) {
          aktualnosci.push({
            [tempAktualnosci[i * 10]]: tempAktualnosci[i * 10 + 1],
            [tempAktualnosci[i * 10 + 2]]: tempAktualnosci[i * 10 + 3],
            [tempAktualnosci[i * 10 + 4]]: tempAktualnosci[i * 10 + 5],
            [tempAktualnosci[i * 10 + 6]]: tempAktualnosci[i * 10 + 7],
            [tempAktualnosci[i * 10 + 8]]: tempAktualnosci[i * 10 + 9],
          });
        }
        for (let i = 0; i < aktualnosci.length; i++) {
          aktualnosci[i].zdjecia = aktualnosci[i].zdjecia.split(",");
        }
        THAT.setState({ aktualnosci, loaded: true });
      }
    };
    let formData = new FormData();
    formData.append("table", "aktualnosci");
    formData.append("columns", ["id", "tytul", "data", "opis", "zdjecia"]);
    formData.append("limit", 5);
    formData.append("reverse", true);
    xmlhttp.open(
      "POST",
      "https://architektura.podlasie.org/php-queries/public/aktualnosci-query.php",
      true
    );
    xmlhttp.send(formData);
  }

  loadMoreNews = () => {
    this.setState({ loaded: false, limit: this.state.limit + 4 });
    let xmlhttp = new XMLHttpRequest();
    const THAT = this;
    xmlhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        // tempAktualnosci are in array, divided like JSON, but without quotes and braces
        // (JSON doesn't work because of new lines or hard enters or something)
        let tempAktualnosci = this.responseText
          // .slice(0, -17)
          .split(",RANDOMSTRING!@#,");
        // Convert tempAktualnosci to an array of objects (aktualnosci)
        let aktualnosci = [];
        for (let i = 0; i < Math.floor(tempAktualnosci.length / 10); i++) {
          aktualnosci.push({
            [tempAktualnosci[i * 10]]: tempAktualnosci[i * 10 + 1],
            [tempAktualnosci[i * 10 + 2]]: tempAktualnosci[i * 10 + 3],
            [tempAktualnosci[i * 10 + 4]]: tempAktualnosci[i * 10 + 5],
            [tempAktualnosci[i * 10 + 6]]: tempAktualnosci[i * 10 + 7],
            [tempAktualnosci[i * 10 + 8]]: tempAktualnosci[i * 10 + 9],
          });
        }
        for (let i = 0; i < aktualnosci.length; i++) {
          aktualnosci[i].zdjecia = aktualnosci[i].zdjecia.split(",");
        }
        aktualnosci = [...THAT.state.aktualnosci, ...aktualnosci];
        THAT.setState({
          aktualnosci,
          loaded: true,
        });
      }
    };
    let formData = new FormData();
    formData.append("table", "aktualnosci");
    formData.append("columns", ["id", "tytul", "data", "opis", "zdjecia"]);
    formData.append("limit", 4);
    formData.append("limitOffset", this.state.limit);
    if (this.state.startDate)
      formData.append("startDate", new Date(this.state.startDate).getTime());
    if (this.state.endDate)
      formData.append("endDate", new Date(this.state.endDate).getTime());
    if (this.state.phrase) formData.append("phrase", this.state.phrase);
    formData.append("reverse", this.reverse);
    xmlhttp.open(
      "POST",
      "https://architektura.podlasie.org/php-queries/public/aktualnosci-query.php",
      true
    );
    xmlhttp.send(formData);
  };

  filter = () => {
    let startDate = document.getElementById("startDate").value;
    let endDate = document.getElementById("endDate").value;
    let phrase = document.getElementById("phrase").value;
    this.reverse = this.state.reverse;

    this.setState({
      startDate,
      endDate,
      phrase,
      aktualnosci: [],
      loaded: false,
      limit: 5,
    });

    let xmlhttp = new XMLHttpRequest();
    const THAT = this;
    xmlhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        // tempAktualnosci are in array, divided like JSON, but without quotes and braces
        // (JSON doesn't work because of new lines or hard enters or something)
        let tempAktualnosci = this.responseText
          // .slice(0, -17)
          .split(",RANDOMSTRING!@#,");
        // Convert tempAktualnosci to an array of objects (aktualnosci)
        let aktualnosci = [];
        for (let i = 0; i < Math.floor(tempAktualnosci.length / 10); i++) {
          aktualnosci.push({
            [tempAktualnosci[i * 10]]: tempAktualnosci[i * 10 + 1],
            [tempAktualnosci[i * 10 + 2]]: tempAktualnosci[i * 10 + 3],
            [tempAktualnosci[i * 10 + 4]]: tempAktualnosci[i * 10 + 5],
            [tempAktualnosci[i * 10 + 6]]: tempAktualnosci[i * 10 + 7],
            [tempAktualnosci[i * 10 + 8]]: tempAktualnosci[i * 10 + 9],
          });
        }
        for (let i = 0; i < aktualnosci.length; i++) {
          aktualnosci[i].zdjecia = aktualnosci[i].zdjecia.split(",");
        }
        aktualnosci = [...THAT.state.aktualnosci, ...aktualnosci];
        THAT.setState({
          aktualnosci,
          loaded: true,
        });
      }
    };
    let formData = new FormData();
    formData.append("table", "aktualnosci");
    formData.append("columns", ["id", "tytul", "data", "opis", "zdjecia"]);
    if (startDate) formData.append("startDate", new Date(startDate).getTime());
    if (endDate) formData.append("endDate", new Date(endDate).getTime());
    if (phrase) formData.append("phrase", phrase);
    formData.append("limit", 5);
    formData.append("reverse", this.reverse);
    xmlhttp.open(
      "POST",
      "https://architektura.podlasie.org/php-queries/public/aktualnosci-query.php",
      true
    );
    xmlhttp.send(formData);
  };

  renderNews = () => {
    if (!this.state.aktualnosci[0]) return null;

    let elements = [];

    let length =
      this.state.aktualnosci.length === this.state.limit
        ? this.state.limit - 1
        : this.state.aktualnosci.length;

    for (let i = 0; i < length; i++) {
      setTimeout(() => {
        document.getElementsByClassName("big-news-tile-desc")[i].innerHTML =
          news.opis;
      }, 0);

      let news = this.state.aktualnosci[i];
      elements.push(
        <Link to={`/aktualnosc/${news.id}`} className="big-news-tile" key={i}>
          <div className="big-news-tile-content">
            <div className="big-news-tile-title">{news.tytul}</div>
            <div className="big-news-tile-date">
              <ClockIcon />
              <div>{formatDate(news.data)}</div>
            </div>
            <div className="big-news-tile-desc"></div>
          </div>
          <div className="big-news-tile-image">
            <div>
              <img
                src={`https://architektura.podlasie.org/${news.zdjecia[0]}`}
                alt=""
              />
            </div>
          </div>
        </Link>
      );
    }

    return elements;
  };

  render() {
    return (
      <div id="content" style={{ paddingBottom: "120px" }}>
        <MetaTags>
          <title>Aktualności | Drewniane Podlasie</title>
          <meta
            id="meta-description"
            name="description"
            content="Co się dzieje na podlasiu? Wiadomości dotyczące drewnianego budownictwa, podlaskiej kultury i mieszkańców."
          />
        </MetaTags>
        <div id="objects-top-bar">
          <div className="title">Aktualności</div>
          <button
            className="objects-filters-button"
            onClick={() => {
              this.setState({ showFilters: !this.state.showFilters });
            }}
          >
            Filtry
          </button>
        </div>
        <div
          id="objects-filters-body"
          style={{
            display: this.state.showFilters ? "" : "none",
          }}
        >
          <div className="objects-filters-title">Wyszukiwanie</div>
          <div
            className="home-contact-form-input-wrapper objects-filters-input"
            style={{ maxWidth: "100%" }}
          >
            <input type="text" id="phrase" />
            <label htmlFor="phrase" style={{ background: "#f6f6f6" }}>
              Słowo kluczowe
            </label>
          </div>
          <div className="objects-filters-title">Data</div>
          <div className="home-contact-form-input-wrapper objects-filters-input">
            <input type="date" id="startDate" />
            <label htmlFor="startDate" style={{ background: "#f6f6f6" }}>
              Od
            </label>
          </div>
          <div className="home-contact-form-input-wrapper objects-filters-input">
            <input type="date" id="endDate" />
            <label htmlFor="endDate" style={{ background: "#f6f6f6" }}>
              Do
            </label>
          </div>
          {/* <button
            id="news-filters-sort-button"
            onClick={() => {
              this.setState({ reverse: !this.state.reverse });
            }}
          >
            <div
              style={{ transform: this.state.reverse ? "rotate(180deg)" : "" }}
            ></div>
            {this.state.reverse ? "Od najnowszego" : "Od najstarszego"}
          </button> */}
          <button
            className="objects-filters-button"
            id="objects-filters-filter-button"
            onClick={this.filter}
          >
            Zapisz
          </button>
        </div>
        {this.renderNews()}
        {this.state.loaded ? null : (
          <div id="loading" title="Ładowanie">
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
        {this.state.aktualnosci.length === this.state.limit ? (
          <button id="news-more" onClick={this.loadMoreNews}>
            Więcej wpisów
          </button>
        ) : null}
      </div>
    );
  }
}
