import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./ObjectTile.css";

export default class ObjectTile extends Component {
  render() {
    return (
      <Link className="object-tile" to={`/obiekt/${this.props.object.id}`}>
        <div className="object-tile-image">
          <div>
            <img
              src={`https://architektura.podlasie.org/${this.props.object.zdjecia[0]}`}
              alt="Obiekt"
            />
          </div>
        </div>
        <div className="object-tile-name">{this.props.object.nazwa}</div>
        <div className="object-tile-location">
          {this.props.object.miejscowosc}
        </div>
      </Link>
    );
  }
}
