import React, { Component } from "react";
import { MetaTags } from "react-meta-tags";

import "./Historia.css";

export default class Historia extends Component {
  render() {
    return (
      <div
        id="content"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <MetaTags>
          <title>Historia drewnianego budownictwa | Drewniane Podlasie</title>
          <meta
            id="meta-description"
            name="description"
            content="Odkryj historię drewnianego budownictwa na podlasiu, jak powstały dzisiejsze tradycje i dlaczego nie wolno o nich zapominać."
          />
        </MetaTags>
        <div id="a-title" className="title-top">
          HISTORIA DREWNIANEGO BUDOWNICTWA NA PODLASIU
        </div>
        <div id="a-divider"></div>
        <div id="historia-content">
          <div id="historia-images">
            <div className="historia-image">
              <img
                src="https://architektura.podlasie.org/zdjecia/zdjecia/Bialowieza_dworek_gubernatora.webp"
                alt="Białowieża - dworek gubernatora"
              />
            </div>
            <div className="historia-image">
              <img
                src="https://architektura.podlasie.org/zdjecia/zdjecia/Kleszczele_dworzec.webp"
                alt="Kleszczele - dworzec"
              />
            </div>
            <div className="historia-image">
              <img
                src="https://architektura.podlasie.org/zdjecia/zdjecia/Czyze.webp"
                alt="Czyże"
              />
            </div>
            <div className="historia-image">
              <img
                src="https://architektura.podlasie.org/zdjecia/zdjecia/Puchly_cerkiew.webp"
                alt="Puchły - cerkiew"
              />
            </div>
          </div>
          <div id="historia-desc">
            <div>
              Chociaż lata świetności niezwykle bogatego zdobnictwa
              architektonicznego już minęły, zagadnienie związane z jego
              pochodzeniem nie zostało do końca wyjaśnione. Istnieje wiele
              opracowań, badań, historii, ale żadne z nich nie precyzuje źródła
              i dokładnego czasu pojawienia się na Podlasiu ażurowych ozdób
              wycinanych w drewnie. Na temat należy spojrzeć wieloaspektowo.
              <br />
            </div>
            <div style={{ marginTop: "1rem" }}>
              Zasadnicze znaczenie miała przynależność całej Białostocczyzny do
              Imperium Rosyjskiego, gdzie zdobnictwo było szczególnie rozwinięte
              już w XIX wieku. Mieszkańcy guberni grodzieńskiej (w jej granicach
              znajdowała się dzisiejsza Białostocczyzna), poprzez kontakty z
              ludnością rosyjską, mieli możliwość zobaczenia tamtejszej
              ornamentyki. <br />
            </div>
            <div style={{ marginTop: "1rem" }}>
              Po drugie, ogromne znaczenie miało „bieżeństwo”. Było to masowe
              wysiedlenie ludności prawosławnej w głąb Rosji. Wycofująca się
              armia rosyjska, stosując propagandową akcję, od wiosny do jesieni
              1915 roku zmusiła do ucieczki miliony osób zamieszkujących
              zachodnie gubernie (w literaturze podaje się od 3 do 6 milionów
              osób). Wojsko, stosując taktykę spalonej ziemi, zniszczyło majątek
              tutejszej ludności. Mimo ogromnej tragedii, z jaką wiąże się ta
              historia, dopatruje się w niej pewnych przesłanek dotyczących
              inspiracji architektonicznych. Po kilku latach, gdy część osób
              postanowiła wrócić do swoich rodzinnych miejscowości, rozpoczął
              się powolny proces odbudowy osad. Poznane zdobnictwo rosyjskich
              wsi i miasteczek, przyczyniło się do rozwoju zdobnictwa na
              Podlasiu. <br />
            </div>
            <div style={{ marginTop: "1rem" }}>
              Chociaż wiele osób przypisuje „bieżeństwu” kluczowe znaczenie w
              rozwoju zdobnictwa budynków mieszkalnych, już na przełomie XIX i
              XX wieku występowały na Białostocczyźnie różnorodnie zdobione
              obiekty. Nie było ono jednak powszechne ze względu na wysoki koszt
              wykonania poszczególnych ornamentów. Na dekorowanie domu misternie
              wyciętymi ozdobami mogli pozwolić sobie tylko nieliczni
              gospodarze. <br />
            </div>
            <div style={{ marginTop: "1rem" }}>
              Na powstanie zdobnictwa wpływ miało również wznoszenie budynków
              kolejowych przez rosyjskich rzemieślników w II połowie XIX wieku.
              Zauważono podobieństwo motywów zdobniczych pomiędzy zdobnictwem
              budynków rządowych, w tym również stacji kolejowych i mieszkań
              należących do funkcjonariuszy państwowych, a architekturą w tzw.
              dacznym stylu Ropeta – wysoce popularnym w Rosji. Wraz z napływem
              rosyjskich rzemieślników, na obszarze obecnej Polski
              północno-wschodniej masowo pojawiły się detale zdobnicze. Jedną z
              najbardziej popularnych ozdób były nadokienniki, w owym czasie
              zwane nalicznikami. Nie rzadko, na wzory lokalnych motywów wpływ
              miał jeden budynek, bogaty w zdobienia i rozpoznawalny w całym
              regionie. Dla mieszkańców Podlasia takim wzorcem mógł być carski
              dwór w Białowieży. Mimo, że dla większości osób stanowił on
              niedościgniony ideał, próbowano choć w niewielkim stopniu
              skopiować jego elementy. Należy jeszcze raz podkreślić, iż
              zamożność właściciela wyznaczała możliwość posiadania bardziej lub
              mniej zdobionego domu. Poniekąd znaczenie miały również cerkwie.
              Za przykład podaje się cerkwie św. Anny i św. Archanioła w Starym
              Korninie (obecnie gmina Dubicze Cerkiewne). Charakterystycznym
              zdobieniem była ząbkowana listwa, którą obecnie można dostrzec na
              pobliskich budynkach mieszkalnych.
              <br />
            </div>
            <div style={{ marginTop: "1rem" }}>
              I coś o czym bardzo często się zapomina a jest niezwykle istotne –
              niezbędne były odpowiednie warunki techniczne, które umożliwiły
              wykonywanie elementów zdobniczych. Początkowo – mniej więcej do II
              połowy XIX wieku – wykorzystywano proste narzędzia, takie jak
              siekiera, dłuto, ciosło, cyrkiel i nóż. Deski rozszczepiano przy
              pomocy żelaznych klinów. Nowe możliwości ciesielskie pojawiły się
              wraz z wynalezieniem piły do drewna. Dzięki niej deski stały się
              ogólnodostępnym materiałem budulcowym. Używano ich przede
              wszystkim do szalowania zewnętrznych ścian budynku oraz szczytów.
              Skomplikowane detale zdobnicze możliwe były do wykonania przy
              użyciu piłki o wąskim brzeszczocie. Określano ją mianem laubzegi.
              Kolejnym, nowym narzędziem był świder z ostrymi wiertłami,
              umożliwiający precyzyjne wycięcie otworów. Natomiast odpowiednie
              profile desek uzyskiwano posługując się strugami z różnie
              ustawionymi krawędziami ostrza noża. Dzięki deskom z wyżłobionymi
              rowkami uzyskiwano dodatkowy efekt zdobniczy. <br />
            </div>
            <div style={{ marginTop: "1rem" }}>
              Podsumowując, geneza zdobnictwa nie jest tak jednoznaczna jakby
              się mogło wydawać. Szczególnie, że mimo zapożyczania
              architektonicznego stylu drewnianych budynków z obszaru Rosji, nie
              było to bezrefleksyjne powielanie takich samych wzorów. Mówi się
              wręcz o specyficznym charakterze detali zdobniczych na Podlasiu.
            </div>
          </div>
        </div>
      </div>
    );
  }
}
