import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { MetaTags } from "react-meta-tags";

import "./Obiekt.css";

import { ReactComponent as GridIcon } from "../../images/icons/grid.svg";
import { ReactComponent as SearchIcon } from "../../images/icons/search.svg";

class Obiekt extends Component {
  rodzaj = (nazwa) => {
    if (nazwa === "budynek mieszkalny") {
      return "Budynek mieszkalny";
    } else if (nazwa === "budynek gospodarczy") {
      return "Budynek gospodarczy";
    } else if (nazwa === "cmentarz") {
      return "Cmentarz";
    } else if (nazwa === "budynek uzytecznosci publicznej") {
      return "Budynek uzytecznosci publicznej";
    } else if (nazwa === "cerkiew") {
      return "Cerkiew";
    } else if (nazwa === "kosciol") {
      return "Kościół";
    } else if (nazwa === "mala architektura") {
      return "Mała architektura";
    } else if (nazwa === "uklad przestrzenny") {
      return "Układ przestrzenny";
    } else if (nazwa === "skanseny i zagrody") {
      return "Skanseny i zagrody";
    }
  };

  zabytek = (nazwa) => {
    if (nazwa === "rejestr zabytkow") {
      return "Rejestr zabytków";
    } else if (nazwa === "wojewodzka ewidencja zabytkow") {
      return "Wojewódzka ewidencja zabytków";
    } else if (nazwa === "gminna ewidencja zabytkow") {
      return "Gminna ewidencja zabytków";
    } else if (nazwa === "pomnik historii") {
      return "Pomnik historii";
    }
  };

  state = {
    obiekt: {},
    bigPhoto: "",
    bigPhotoIndex: -1,
    obiekty: [],
  };

  componentDidMount() {
    const THAT = this;
    this.getObiekt();
    // Get the amount of objects
    let xmlhttp2 = new XMLHttpRequest();
    xmlhttp2.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        let response = JSON.parse(`[${this.responseText.slice(0, -1)}]`);
        let obiekty = [];
        for (let i = 0; i < response.length; i++) obiekty.push(response[i].id);
        THAT.setState({ obiekty });
      }
    };
    let formData2 = new FormData();
    formData2.append("table", "obiekty");
    formData2.append("columns", ["id"]);
    xmlhttp2.open(
      "POST",
      "https://architektura.podlasie.org/php-queries/public/public-query.php",
      true
    );
    xmlhttp2.send(formData2);
  }

  getObiekt = () => {
    let xmlhttp = new XMLHttpRequest();
    const THAT = this;
    xmlhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        let obiekt = JSON.parse(`[${this.responseText.slice(0, -1)}]`);
        // Convert zdjecia to array
        obiekt[0].zdjecia = obiekt[0].zdjecia.split(",");
        setTimeout(() => {
          // Set opis
          document.getElementById("a-content").innerHTML = obiekt[0].opis;

          let theImage = document
            .getElementById("a-img")
            .getElementsByTagName("img")[0];
          theImage.style.opacity = "0";
          THAT.setState({ bigPhoto: obiekt[0].zdjecia[0], bigPhotoIndex: 0 });

          let miniImg = new Image();
          miniImg.src = `https://architektura.podlasie.org/${obiekt[0].zdjecia[0]}`;
          miniImg.onload = () => {
            theImage.style.opacity = "1";
          };

          let img = new Image();
          img.src = `https://architektura.podlasie.org/${
            obiekt[0].zdjecia[obiekt[0].zdjecia.length / 2]
          }`;
          img.onload = () => {
            THAT.setState({
              bigPhoto: obiekt[0].zdjecia[obiekt[0].zdjecia.length / 2],
              bigPhotoIndex: obiekt[0].zdjecia.length / 2,
            });
          };
        }, 0);
        // Set obiekt
        THAT.setState({ obiekt: { ...obiekt[0] } });
      }
    };
    let formData = new FormData();
    formData.append("table", "obiekty");
    formData.append("columns", [
      "id",
      "nazwa",
      "opis",
      "data",
      "miejscowosc",
      "gmina",
      "rodzaj",
      "czy_zabytek",
      "czy_zwiedzanie",
      "czy_jedzenie",
      "czy_nocleg",
      "lat",
      "lon",
      "zdjecia",
    ]);
    formData.append("id", this.props.match.params.id);
    xmlhttp.open(
      "POST",
      "https://architektura.podlasie.org/php-queries/public/public-query.php",
      true
    );
    xmlhttp.send(formData);
  };

  changePhoto = (sign) => {
    this.setState({
      bigPhoto: this.state.obiekt.zdjecia[
        this.state.bigPhotoIndex + sign - this.state.obiekt.zdjecia.length / 2
      ],
      bigPhotoIndex: this.state.bigPhotoIndex + sign,
    });

    let img = new Image();
    img.src = `https://architektura.podlasie.org/${
      this.state.obiekt.zdjecia[this.state.bigPhotoIndex + sign]
    }`;
    img.onload = () => {
      this.setState({
        bigPhoto: this.state.obiekt.zdjecia[this.state.bigPhotoIndex],
      });
    };
  };

  changeBigPhoto = (i) => {
    this.setState({
      bigPhoto: this.state.obiekt.zdjecia[i],
      bigPhotoIndex: this.state.obiekt.zdjecia.length / 2 + i,
    });

    let img = new Image();
    img.src = `https://architektura.podlasie.org/${
      this.state.obiekt.zdjecia[this.state.obiekt.zdjecia.length / 2 + i]
    }`;
    img.onload = () => {
      this.setState({
        bigPhoto: this.state.obiekt.zdjecia[
          this.state.obiekt.zdjecia.length / 2 + i
        ],
      });
    };
  };

  renderImages = () => {
    let elements = [];

    for (let i = 0; i < this.state.obiekt.zdjecia.length / 2; i++) {
      elements.push(
        <div>
          <img
            src={`https://architektura.podlasie.org/${this.state.obiekt.zdjecia[i]}`}
            alt=""
            onClick={() => {
              this.changeBigPhoto(i);
            }}
          />
          <SearchIcon />
        </div>
      );
    }

    return elements;
  };

  render() {
    if (!this.state.obiekt.id)
      return (
        <div id="loading" title="Ładowanie">
          <div></div>
          <div></div>
          <div></div>
        </div>
      );

    const obiektIndex = this.state.obiekty.indexOf(this.state.obiekt.id);
    let prevIndex = obiektIndex - 1;
    let nextIndex = obiektIndex + 1;
    if (obiektIndex === 0) prevIndex = this.state.obiekty.length - 1;
    else if (obiektIndex === this.state.obiekty.length - 1) nextIndex = 0;

    setTimeout(() => {
      document.getElementById("a-img-label").style.left = `${
        document.getElementsByTagName("img")[0].getBoundingClientRect().left -
        document.getElementById("a-img").getBoundingClientRect().left
      }px`;
    }, 0);

    return (
      <div
        id="content"
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <MetaTags>
          <title>{this.state.obiekt.nazwa} | Drewniane Podlasie</title>
          <meta
            id="meta-description"
            name="description"
            content={this.state.obiekt.opis}
          />
        </MetaTags>
        <div id="a-img" style={{ background: "transparent" }}>
          <div>
            {this.state.bigPhotoIndex > this.state.obiekt.zdjecia.length / 2 ? (
              <button
                className="a-img-change-button"
                onClick={() => {
                  this.changePhoto(-1);
                }}
              ></button>
            ) : null}
            <img
              src={`https://architektura.podlasie.org/${this.state.bigPhoto}`}
              alt=""
              style={{ objectFit: "contain" }}
            />
            <div id="a-img-label">
              {this.state.bigPhotoIndex !== -1 &&
              this.state.obiekt.zdjecia[this.state.bigPhotoIndex]
                ? this.state.obiekt.zdjecia[this.state.bigPhotoIndex].slice(
                    this.state.obiekt.zdjecia[this.state.bigPhotoIndex].indexOf(
                      "prefix-"
                    ) !== -1
                      ? 30
                      : 17,
                    -5
                  )
                : ""}
            </div>
            {this.state.bigPhotoIndex < this.state.obiekt.zdjecia.length - 1 ? (
              <button
                className="a-img-change-button"
                onClick={() => {
                  this.changePhoto(1);
                }}
              ></button>
            ) : null}
          </div>
        </div>
        <div id="o-image-row">{this.renderImages()}</div>
        <div id="a-title-row">
          <Link
            to={`/obiekt/${this.state.obiekty[prevIndex]}`}
            onClick={() => setTimeout(() => this.getObiekt(), 0)}
            className="a-title-row-button"
          >{`< Poprzedni obiekt`}</Link>
          <div id="a-title">{this.state.obiekt.nazwa}</div>
          <Link
            to={`/obiekt/${this.state.obiekty[nextIndex]}`}
            onClick={() => setTimeout(() => this.getObiekt(), 0)}
            className="a-title-row-button"
          >{`Następny obiekt >`}</Link>
        </div>
        <div id="o-miejscowosc">
          {this.state.obiekt.miejscowosc}, {this.state.obiekt.gmina}
        </div>
        <div id="o-rodzaj">
          <b>Rodzaj:</b> {this.rodzaj(this.state.obiekt.rodzaj)}
        </div>
        {this.state.obiekt.data ? (
          <div id="o-data">
            <b>Chronologia:</b> {this.state.obiekt.data}
          </div>
        ) : (
          ""
        )}
        {this.state.obiekt.czy_zabytek === "nie zabytek" ? null : (
          <div id="o-zabytek">
            <b>Forma ochrony:</b> {this.zabytek(this.state.obiekt.czy_zabytek)}
          </div>
        )}
        <div id="o-icon-row"></div>
        <div id="a-content"></div>
        <div id="a-divider"></div>
        <div id="o-mapa">
          <Map
            google={this.props.google}
            zoom={13}
            initialCenter={{
              lat: parseInt(this.state.obiekt.lat) / 10000000,
              lng: parseInt(this.state.obiekt.lon) / 10000000,
            }}
          >
            <Marker
              position={{
                lat: parseInt(this.state.obiekt.lat) / 10000000,
                lng: parseInt(this.state.obiekt.lon) / 10000000,
              }}
              name={this.state.obiekt.nazwa}
              style={{ cursor: "default" }}
            />
          </Map>
        </div>
        <div id="a-buttons">
          <Link
            to="/obiekty"
            className="square-button"
            style={{
              borderColor: "var(--accent-color)",
              fill: "var(--accent-color)",
            }}
            title="Wszystkie obiekty"
          >
            <GridIcon />
          </Link>
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyB5GQymYsL1RX0uH9Qe0fAUv18Xn6tnpRY",
})(Obiekt);
