import React, { Component } from "react";

import "./Footer.css";

import { ReactComponent as FacebookLogo } from "../../images/facebook.svg";
import { ReactComponent as InstagramLogo } from "../../images/instagram.svg";
import { ReactComponent as YoutubeLogo } from "../../images/youtube.svg";
import { ReactComponent as PhoneIcon } from "../../images/icons/phone.svg";
import { ReactComponent as MailIcon } from "../../images/icons/mail.svg";
import { ReactComponent as MarkerIcon } from "../../images/icons/marker.svg";

export default class Footer extends Component {
  hideCookiesInfo = () => {
    document.getElementById("cookies-info").style.display = "none";
  };

  render() {
    return (
      <div id="footer-body">
        <div id="footer-info">
          <div className="footer-column">
            <img
              src={require("../../images/sdp-logo.webp")}
              alt="Logo SDP"
              className="footer-column-line"
            />
            <div className="footer-column-line">
              <PhoneIcon />
              <div>602 430 772</div>
            </div>
            <div className="footer-column-line">
              <MailIcon />
              <div>sdp@podlasie.org</div>
            </div>
            <div className="footer-column-line">
              <MarkerIcon />
              <div>
                <div>Puchły 62</div>
                <div>17-210 Narew</div>
              </div>
            </div>
            <div id="footer-social-media">
              <a
                href="https://www.facebook.com/dziedzictwopodlasia/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookLogo />
              </a>
              <a href="instagram.com" target="_blank" rel="noopener noreferrer">
                <InstagramLogo />
              </a>
              <a
                href="https://www.youtube.com/channel/UC7w1ox6Zbd6jDSSl4D6Q4Yw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YoutubeLogo />
              </a>
            </div>
          </div>
          <div className="footer-column">
            <div className="footer-column-title">WESPRZYJ NAS</div>
            <div className="footer-column-line">
              Przekaż 1% podatku na Stowarzyszenie Dziedzictwo Podlasia
            </div>
            <div className="footer-column-line">KRS: 0000205543</div>
            <div className="footer-column-line footer-column-title">
              Informacje
            </div>
            <div
              className="footer-column-line"
              style={{ flexDirection: "column" }}
            >
              <div>O projekcie</div>
              <div>Polityka prywatności</div>
              <div>Status SDP</div>
            </div>
          </div>
          <div className="footer-column">
            <div className="footer-column-title">Zobacz również</div>
            <div className="footer-column-line">
              <a
                href="https://podlasie.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Stowarzyszenie Dziedzictwo Podlasia
              </a>
            </div>
            <div className="footer-column-line">
              <a
                href="https://www.nid.pl/pl/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Narodowy Instytut Dziedzictwa
              </a>
            </div>
            <div className="footer-column-line">
              <a
                href="https://www.gov.pl/web/kultura/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ministerstwo Kultury i Dziedzictwa Narodowego
              </a>
            </div>
          </div>
          <div id="footer-divider"></div>
          <div className="footer-column">
            <div className="footer-column-title">Sponsorzy</div>
            <img
              src={require("../../images/podlaskie.webp")}
              alt="Logo Województwo Podlaskie"
              className="footer-column-line"
            />
            <div style={{ marginTop: "17px" }}>
              Zadanie publiczne jest współfinansowane ze środków Województwa
              Podlaskiego
            </div>
            <img
              src={require("../../images/logo_bp.webp")}
              alt="Logo Bielsk Podlaski"
              className="footer-column-line"
            />
            <div style={{ marginTop: "17px" }}>
              Zadanie współfinansowane ze środków Budżetu Miasta Bielsk Podlaski
            </div>
          </div>
          <div className="footer-column" id="footer-column-herb">
            <img
              src={require("../../images/mkidn-logo.webp")}
              alt="Logo MKiDN"
              className="footer-column-line"
            />
            <div className="footer-column-line">
              Dofinansowano ze środków Ministra Kultury i Dziedzictwa Narodowego
              pochodzących z Funduszu Promocji Kultury.
            </div>
          </div>
        </div>
        <div id="cookies-info">
          Ta strona wykorzystuje pliki cookies{" "}
          <button onClick={this.hideCookiesInfo} title="Zamknij"></button>
        </div>
        <div id="footer-bottom">
          <div>2019 &copy; Stowarzyszenie Dziedzictwo Podlasie</div>
          <div>Projekt Rosik & Pawlonka | Realizacja Paweł Stepaniuk</div>
        </div>
      </div>
    );
  }
}
