import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./NewsTile.css";

import { formatDate } from "../formatDate";

export default class NewsTile extends Component {
  render() {
    if (!this.props.news || !this.props.news.id) return null;

    return (
      <Link className="news-tile" to={`/aktualnosc/${this.props.news.id}`}>
        <div className="news-tile-image">
          <div>
            <img
              src={`https://architektura.podlasie.org/${this.props.news.zdjecia[0]}`}
              alt=""
            />
          </div>
        </div>
        <div className="news-tile-date">{formatDate(this.props.news.data)}</div>
        <div className="news-tile-title">{this.props.news.tytul}</div>
        <div className="news-tile-opis">{this.props.news.opis}</div>
        <div className="news-tile-read-more">Czytaj więcej</div>
      </Link>
    );
  }
}
