import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { loadReCaptcha } from "react-recaptcha-google";

import Topbar from "./elements/topbar/Topbar";
import Footer from "./elements/footer/Footer";
import Home from "./pages/home/Home";
import Obiekty from "./pages/obiekty/Obiekty";
import Obiekt from "./pages/obiekt/Obiekt";
import Aktualnosc from "./pages/aktualnosc/Aktualnosc";
import Aktualnosci from "./pages/aktualnosci/Aktualnosci";
import Historia from "./pages/historia/Historia";
import Mapa from "./pages/mapa/Mapa";
import Wykonawcy from "./pages/wykonawcy/Wykonawcy";
import Projekt from "./pages/projekt/Projekt";
import KatalogZdobien from "./pages/katalog-zdobien/KatalogZdobien";
import Slownik from "./pages/slownik/Slownik";
import JakRemontowac from "./pages/jak-remontowac/JakRemontowac";

function App() {
  loadReCaptcha();

  return (
    <BrowserRouter>
      <div>
        <Route path="/" component={MainApp} />
      </div>
    </BrowserRouter>
  );
}

function MainApp(params) {
  if (params.location.pathname.indexOf("/panel") !== -1) return null;

  window.scrollTo(0, 0);

  return (
    <div className="body">
      <Topbar />
      <Route path="/" component={Home} exact={true} />
      <Route path="/aktualnosci" component={Aktualnosci} exact={true} />
      <Route path="/aktualnosc/:id" component={Aktualnosc} exact={true} />
      <Route path="/historia" component={Historia} exact={true} />
      <Route path="/slownik" component={Slownik} exact={true} />
      <Route path="/mapa" component={Mapa} exact={true} />
      <Route path="/obiekty/:filter" component={Obiekty} exact={true} />
      <Route path="/obiekty" component={Obiekty} exact={true} />
      <Route path="/obiekt/:id" component={Obiekt} exact={true} />
      <Route path="/katalog-zdobien" component={KatalogZdobien} exact={true} />
      <Route path="/jak-remontowac" component={JakRemontowac} exact={true} />
      <Route path="/wykonawcy" component={Wykonawcy} exact={true} />
      <Route path="/projekt" component={Projekt} exact={true} />
      {params.location.pathname.indexOf("/mapa") === -1 &&
      params.location.pathname.indexOf("/projekt") === -1 ? (
        <Footer />
      ) : null}
    </div>
  );
}

export default App;
