import * as THREE from "three";

export const renderOkno = (scene, loader, fileParams) => {
  let oknoParams = [
    0,
    Math.PI,
    -Math.PI / 2,
    -Math.PI / 2,
    Math.PI / 2,
    Math.PI / 2
  ];

  // load a resource
  loader.load(
    // resource URL
    `https://architektura.podlasie.org/projekt/${fileParams.gmina}/${fileParams.nazwa}`,

    // onLoad callback
    function(texture) {
      for (let i = 0; i < oknoParams.length; i++) {
        let material = new THREE.MeshBasicMaterial({
          map: texture,
          transparent: true
        });
        let geometry = new THREE.PlaneGeometry(
          texture.image.width / 1000,
          texture.image.height / 1000,
          1,
          1
        );
        let okno = new THREE.Mesh(geometry, material);

        okno.position.set(...fileParams.pozycja[i]);
        okno.rotation.y = oknoParams[i];
        scene.add(okno);
      }
    },

    // onProgress callback currently not supported
    undefined,

    // onError callback
    function(err) {
      console.error("An error happened. (okno)");
    }
  );
};
