import React, { Component } from "react";
import { MetaTags } from "react-meta-tags";

export default class Slownik extends Component {
  render() {
    return (
      <div
        id="content"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <MetaTags>
          <title>
            Słownik terminów architektonicznych | Drewniane Podlasie
          </title>
          <meta
            id="meta-description"
            name="description"
            content="Odkryj historię drewnianego budownictwa na podlasiu, jak powstały dzisiejsze tradycje i dlaczego nie wolno o nich zapominać."
          />
        </MetaTags>
        <div id="a-title" className="title-top">
          SŁOWNIK TERMINÓW ARCHITEKTONICZNYCH
        </div>
        <div id="a-img" style={{ background: "transparent" }}>
          <a
            href="https://architektura.podlasie.org/pliki/Slownik_terminow_architektonicznych.pdf"
            target="blank_"
          >
            <img
              src={require("../../images/okladka-slownik.webp")}
              alt="Okładka słownika"
            />
          </a>
        </div>
        <div
          id="a-content"
          style={{ marginBottom: "100px", textAlign: "center" }}
        >
          <a
            href="https://architektura.podlasie.org/pliki/Slownik_terminow_architektonicznych.pdf"
            target="blank_"
            style={{ marginBottom: "100px" }}
          >
            Otwórz słownik
          </a>
        </div>
      </div>
    );
  }
}
