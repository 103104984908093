import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { Link } from "react-router-dom";
import { MetaTags } from "react-meta-tags";

import "./Mapa.css";
import MapaMap from "./MapaMap";

class Mapa extends Component {
  rodzaj = (nazwa) => {
    if (nazwa === "budynek mieszkalny") {
      return "Budynek mieszkalny";
    } else if (nazwa === "budynek gospodarczy") {
      return "Budynek gospodarczy";
    } else if (nazwa === "cmentarz") {
      return "Cmentarz";
    } else if (nazwa === "budynek uzytecznosci publicznej") {
      return "Budynek uzytecznosci publicznej";
    } else if (nazwa === "cerkiew") {
      return "Cerkiew";
    } else if (nazwa === "kosciol") {
      return "Kościół";
    } else if (nazwa === "mala architektura") {
      return "Mała architektura";
    } else if (nazwa === "uklad przestrzenny") {
      return "Układ przestrzenny";
    } else if (nazwa === "skanseny i zagrody") {
      return "Skanseny i zagrody";
    }
  };

  zabytek = (nazwa) => {
    if (nazwa === "rejestr zabytkow") {
      return "Rejestr zabytków";
    } else if (nazwa === "wojewodzka ewidencja zabytkow") {
      return "Wojewódzka ewidencja zabytków";
    } else if (nazwa === "gminna ewidencja zabytkow") {
      return "Gminna ewidencja zabytków";
    } else if (nazwa === "pomnik historii") {
      return "Pomnik historii";
    }
  };

  state = {
    showFilters: false,
    showObject: false,
    activeMarker: {},
    selectedPlace: {},
    obiekty: [],
    obiekt: {},
  };

  onMarkerClick = (props, marker, e) => {
    // Get object
    let xmlhttp = new XMLHttpRequest();
    const THAT = this;
    xmlhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        let obiekt = JSON.parse(`[${this.responseText.slice(0, -1)}]`);
        obiekt[0].zdjecia = obiekt[0].zdjecia.split(",");
        document.getElementById("map-object-info-description").innerHTML =
          obiekt[0].opis;
        THAT.setState({ obiekt: obiekt[0], isLoading: false });
      }
    };
    let formData = new FormData();
    formData.append("table", "obiekty");
    formData.append("columns", [
      "opis",
      "data",
      "rodzaj",
      "czy_zabytek",
      "czy_zwiedzanie",
      "czy_jedzenie",
      "czy_nocleg",
      "zdjecia",
    ]);
    formData.append("id", props.id);
    xmlhttp.open(
      "POST",
      "https://architektura.podlasie.org/php-queries/public/public-query.php",
      true
    );
    xmlhttp.send(formData);

    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showObject: true,
      showFilters: false,
      obiekt: {},
    });
  };

  componentDidMount() {
    if (window.innerWidth > 1120) {
      document.getElementById("title-bar").parentElement.style.transform =
        "translate(0, -50px)";
    }

    // Get objects
    let xmlhttp = new XMLHttpRequest();
    const THAT = this;
    xmlhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        let obiekty = JSON.parse(`[${this.responseText.slice(0, -1)}]`);
        THAT.setState({ obiekty, isLoading: false });
      }
    };
    let formData = new FormData();
    formData.append("table", "obiekty");
    formData.append("columns", ["id", "nazwa", "rodzaj", "lat", "lon"]);
    xmlhttp.open(
      "POST",
      "https://architektura.podlasie.org/php-queries/public/public-query.php",
      true
    );
    xmlhttp.send(formData);
  }

  componentWillUnmount() {
    if (window.innerWidth > 1120) {
      document.getElementById("title-bar").parentElement.style.transform = "";
    }
  }

  toggleFilters = () => {
    this.setState({ showFilters: !this.state.showFilters });
  };

  toggleObject = () => {
    this.setState({
      showObject: !this.state.showObject,
      showFilters: false,
    });
  };

  filter = (e) => {
    let target = e.target;
    setTimeout(() => {
      target.blur();
    }, 0);

    this.setState({ isLoading: true });

    let rodzaj = [];
    if (document.getElementById("budynek-mieszkalny").checked)
      rodzaj.push("budynek mieszkalny");
    if (document.getElementById("budynek-gospodarczy").checked)
      rodzaj.push("budynek gospodarczy");
    if (document.getElementById("cmentarz").checked) rodzaj.push("cmentarz");
    if (document.getElementById("budynek-uzytecznosci-publicznej").checked)
      rodzaj.push("budynek uzytecznosci publicznej");
    if (document.getElementById("cerkiew").checked) rodzaj.push("cerkiew");
    if (document.getElementById("kosciol").checked) rodzaj.push("cerkiew");
    if (document.getElementById("mala-architektura").checked)
      rodzaj.push("mala architektura");
    if (document.getElementById("uklad-przestrzenny").checked)
      rodzaj.push("uklad przestrzenny");
    if (document.getElementById("skanseny").checked)
      rodzaj.push("skanseny i zagrody");

    let zabytek = [];
    if (document.getElementById("rejestr-zabytkow").checked)
      zabytek.push("rejestr zabytkow");
    if (document.getElementById("wojewodzka-ewidencja-zabytkow").checked)
      zabytek.push("wojewodzka ewidencja zabytkow");
    if (document.getElementById("gminna-ewidencja-zabytkow").checked)
      zabytek.push("gminna ewidencja zabytkow");
    if (document.getElementById("pomnik-historii").checked)
      zabytek.push("pomnik historii");

    let funkcja = [];
    if (document.getElementById("zwiedz").checked)
      funkcja.push("czy_zwiedzanie");
    if (document.getElementById("restauracja").checked)
      funkcja.push("czy_jedzenie");
    if (document.getElementById("nocleg").checked) funkcja.push("czy_nocleg");

    let xmlhttp = new XMLHttpRequest();
    const THAT = this;
    xmlhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        let obiekty = JSON.parse(`[${this.responseText.slice(0, -1)}]`);
        THAT.setState({ obiekty, isLoading: false });
      }
    };
    let formData = new FormData();
    formData.append("table", "obiekty");
    formData.append("columns", ["id", "nazwa", "lat", "lon"]);
    if (rodzaj.length) formData.append("rodzaj", rodzaj);
    if (funkcja.length) formData.append("funkcja", funkcja);
    if (zabytek.length) formData.append("zabytek", zabytek);
    if (document.getElementById("miejscowosc").value)
      formData.append(
        "miejscowosc",
        document.getElementById("miejscowosc").value
      );
    if (document.getElementById("gmina").value)
      formData.append("gmina", document.getElementById("gmina").value);
    xmlhttp.open(
      "POST",
      "https://architektura.podlasie.org/php-queries/public/public-query.php",
      true
    );
    xmlhttp.send(formData);
  };

  render() {
    return (
      <div id="mapa-body">
        <MetaTags>
          <title>Mapa | Drewniane Podlasie</title>
          <meta
            id="meta-description"
            name="description"
            content="Znajdź historyczne obiekty w swojej okolicy i odkryj tradycyjną podlaską architekturę na nowo."
          />
        </MetaTags>
        <MapaMap
          obiekty={this.state.obiekty}
          onMarkerClick={this.onMarkerClick}
        ></MapaMap>
        <button id="map-filters-button" onClick={this.toggleFilters}>
          Filtry
        </button>
        {/* FILTERS */}
        <div
          id="map-filters-body"
          style={{ transform: this.state.showFilters ? "translateX(0)" : "" }}
        >
          <button
            className="close-map-filters-button"
            onClick={this.toggleFilters}
            title="Zamknij"
          ></button>
          <div>
            <div className="objects-filters-title">Rodzaj</div>
            <div
              className="objects-filters-rodzaj-body"
              style={{ flexDirection: "column", alignItems: "flex-start" }}
            >
              <div>
                <input type="checkbox" id="budynek-mieszkalny" />
                <label htmlFor="budynek-mieszkalny">Budynek mieszkalny</label>
              </div>
              <div>
                <input type="checkbox" id="budynek-gospodarczy" />
                <label htmlFor="budynek-gospodarczy">Budynek gospodarczy</label>
              </div>
              <div>
                <input type="checkbox" id="cmentarz" />
                <label htmlFor="cmentarz">Cmentarz</label>
              </div>
              <div>
                <input type="checkbox" id="budynek-uzytecznosci-publicznej" />
                <label htmlFor="budynek-uzytecznosci-publicznej">
                  Budynek użyteczności publicznej
                </label>
              </div>
              <div>
                <input type="checkbox" id="cerkiew" />
                <label htmlFor="cerkiew">Cerkiew</label>
              </div>
              <div>
                <input type="checkbox" id="kosciol" />
                <label htmlFor="kosciol">Kościół</label>
              </div>
              <div>
                <input type="checkbox" id="mala-architektura" />
                <label htmlFor="mala-architektura">Mała architektura</label>
              </div>
              <div>
                <input type="checkbox" id="uklad-przestrzenny" />
                <label htmlFor="uklad-przestrzenny">Układ przestrzenny</label>
              </div>
              <div>
                <input type="checkbox" id="skanseny" />
                <label htmlFor="skanseny">Skanseny i zagrody</label>
              </div>
            </div>
            <div className="objects-filters-title">Zabytki</div>
            <div
              className="objects-filters-rodzaj-body"
              style={{ flexDirection: "column", alignItems: "flex-start" }}
            >
              <div>
                <input type="checkbox" id="rejestr-zabytkow" />
                <label htmlFor="rejestr-zabytkow">Rejestr zabytków</label>
              </div>
              <div>
                <input type="checkbox" id="wojewodzka-ewidencja-zabytkow" />
                <label htmlFor="wojewodzka-ewidencja-zabytkow">
                  Wojewódzka ewidencja zabytków
                </label>
              </div>
              <div>
                <input type="checkbox" id="gminna-ewidencja-zabytkow" />
                <label htmlFor="gminna-ewidencja-zabytkow">
                  Gminna ewidencja zabytków
                </label>
              </div>
              <div>
                <input type="checkbox" id="pomnik-historii" />
                <label htmlFor="pomnik-historii">Pomnik historii</label>
              </div>
            </div>
            <div className="objects-filters-title">Funkcja</div>
            <div
              className="objects-filters-rodzaj-body"
              style={{ flexDirection: "column", alignItems: "flex-start" }}
            >
              <div>
                <input type="checkbox" id="zwiedz" />
                <label htmlFor="zwiedz">Odwiedź / Zwiedź</label>
              </div>
              <div>
                <input type="checkbox" id="restauracja" />
                <label htmlFor="restauracja">Tutaj dobrze zjesz</label>
              </div>
              <div>
                <input type="checkbox" id="nocleg" />
                <label htmlFor="nocleg">Przenocuj</label>
              </div>
            </div>
            <div className="objects-filters-title">Lokalizacja</div>
            <div className="home-contact-form-input-wrapper objects-filters-input">
              <input type="text" id="miejscowosc" />
              <label htmlFor="miejscowosc" style={{ background: "#ffffff" }}>
                Miejscowość
              </label>
            </div>
            <div
              className="home-contact-form-input-wrapper objects-filters-input"
              style={{ marginBottom: "121px" }}
            >
              <input type="text" id="gmina" />
              <label htmlFor="gmina" style={{ background: "#ffffff" }}>
                Gmina
              </label>
            </div>
          </div>
          <button
            className="objects-filters-button map-filters-save-button"
            id="objects-filters-filter-button"
            onClick={this.state.isLoading ? null : this.filter}
            style={{
              cursor: this.state.isLoading ? "default" : "",
              background: this.state.isLoading ? "var(--secondary-color)" : "",
              padding: this.state.isLoading ? "0" : "",
            }}
            title={this.state.isLoading ? "Ładowanie" : ""}
          >
            {this.state.isLoading ? (
              <div id="loading" style={{ height: "43px", padding: "0" }}>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              "Zapisz"
            )}
          </button>
        </div>
        {/* OBJECT */}
        <div
          id="map-object-info-modal"
          style={{ transform: this.state.showObject ? "translateX(0)" : "" }}
        >
          <button
            className="close-map-filters-button close-map-object-info"
            onClick={this.toggleObject}
            title="Zamknij"
          ></button>
          <div id="map-object-info-photo">
            {this.state.obiekt.zdjecia ? (
              <img
                src={`https://architektura.podlasie.org/${
                  this.state.obiekt.zdjecia[
                    this.state.obiekt.zdjecia.length / 2
                  ]
                }`}
                alt={this.state.selectedPlace.name}
              />
            ) : null}
          </div>
          <h2>{this.state.selectedPlace.name}</h2>
          <div id="o-rodzaj">
            <b>Rodzaj:</b> {this.rodzaj(this.state.obiekt.rodzaj)}
          </div>
          {this.state.obiekt.data ? (
            <div id="o-data">
              <b>Chronologia:</b> {this.state.obiekt.data}
            </div>
          ) : null}
          {this.state.obiekt.czy_zabytek === "nie zabytek" ? null : (
            <div id="o-zabytek">
              <b>Forma ochrony:</b>{" "}
              {this.zabytek(this.state.obiekt.czy_zabytek)}
            </div>
          )}
          <div id="map-object-info-description"></div>
          <Link
            id="map-object-info-link"
            to={`/obiekt/${this.state.selectedPlace.id}`}
          >
            Przejdź do obiektu
          </Link>
        </div>
      </div>
    );
  }
}

export default Mapa;
