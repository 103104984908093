import React, { Component } from "react";

import "./Projekt.css";
// import { createHouseModel } from "./model";
import { createHouseModel } from "./model-server";

export default class HouseModel extends Component {
  color = "#ffffff";

  state = {
    openModal: true,
    threeD: true,
    elements: {},
    chosenElements: {}
  };

  componentDidMount() {
    setTimeout(() => {
      document.getElementById("miejscowosc").value = this.props.miejscowosc;
    }, 0);

    // Get elements for chosen location
    let THAT = this;
    let xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        let response = JSON.parse(`[${this.responseText.slice(0, -1)}]`);
        let elements = {};
        let chosenElements = {};
        for (let i = 0; i < response.length; i++) {
          // Convert pozycja from text to array
          response[i].pozycja = JSON.parse(
            response[i].pozycja.replace(/'/g, '"')
          );
          response[i].obrot = response[i].obrot
            ? JSON.parse(response[i].obrot.replace(/'/g, '"'))
            : undefined;
          // Filter and sort all elements
          if (elements[response[i].typ]) {
            elements[response[i].typ].push(response[i]);
          } else {
            elements[response[i].typ] = response[i];
          }
          // Set default elements for every category (first element of every category)
          if (!chosenElements[response[i].typ]) {
            chosenElements[response[i].typ] = response[i];
          }
        }
        THAT.setState({ elements, chosenElements });
      }
    };
    let formData = new FormData();
    formData.append("table", "elementy");
    formData.append("columns", ["id", "typ", "nazwa", "pozycja", "obrot"]);
    formData.append("gmina", this.props.miejscowosc);
    xmlhttp.open(
      "POST",
      "https://architektura.podlasie.org/php-queries/public/projekt-query.php",
      true
    );
    xmlhttp.send(formData);
  }

  componentDidUpdate() {
    // Get dimensions
    const THAT = this;
    setTimeout(() => {
      let xmlhttp = new XMLHttpRequest();
      xmlhttp.onreadystatechange = function() {
        if (this.readyState === 4 && this.status === 200) {
          let response = JSON.parse(`${this.responseText.slice(0, -1)}`);
          let gmina = {
            gmina: THAT.props.miejscowosc,
            szerokosc_podstawy: response.szerokosc_podstawy,
            wysokosc_podstawy: response.wysokosc_podstawy,
            szerokosc_dachu: response.szerokosc_dachu,
            wysokosc_dachu: response.wysokosc_dachu
          };
          createHouseModel(gmina, THAT.state.chosenElements, THAT.color);
        }
      };
      let formData = new FormData();
      formData.append("table", "gminy");
      formData.append("columns", [
        "id",
        "szerokosc_podstawy",
        "wysokosc_podstawy",
        "szerokosc_dachu",
        "wysokosc_dachu"
      ]);
      formData.append("gmina", this.props.miejscowosc);
      xmlhttp.open(
        "POST",
        "https://architektura.podlasie.org/php-queries/public/projekt-query.php",
        true
      );
      xmlhttp.send(formData);
    }, 0);
  }

  toggleModal = () => {
    let node =
      document.getElementById("house-model") ||
      document.getElementById("house-project");
    node.style.transition = "0.3s";
    setTimeout(() => {
      this.setState({ openModal: !this.state.openModal });
      setTimeout(() => {
        node.style.transition = "0s";
      }, 0);
    }, 0);
  };

  setColor = e => {
    // Reset all color buttons style
    for (
      let i = 0;
      i < document.getElementById("phm-options-colors-grid").childNodes.length;
      i++
    ) {
      let element = document.getElementById("phm-options-colors-grid")
        .childNodes[i];
      element.style.borderWidth = "1px";
      element.style.width = "38px";
      element.style.height = "38px";
    }
    // Set wider border to the chosen color button
    e.target.style.borderWidth = "2px";
    e.target.style.width = "36px";
    e.target.style.height = "36px";

    this.color = e.target.style.background;
  };

  saveChanges = () => {
    // Update location
    this.props.changeMiejscowosc(document.getElementById("miejscowosc").value);
    // Reset three.js canvas
    if (document.getElementById("house-model")) {
      document.getElementById("house-model").innerHTML = "";
    }
    // Update remaining setting
    let checkElement = name => {
      // Check if select exists, if not, don't change the value
      if (document.getElementById(name)) {
        for (let i = 0; i < this.state.elements[name].length; i++) {
          if (
            document.getElementById(name).value === this.state.elements[name].id
          ) {
            return this.state.elements[name];
          }
        }
      }
      return chosenElements[name];
    };

    let chosenElements = JSON.parse(JSON.stringify(this.state.elements));
    chosenElements["okno"] = checkElement("okno");
    chosenElements["naroznik"] = checkElement("naroznik");
    chosenElements["wiatrownica"] = checkElement("wiatrownica");
    chosenElements["szczyt"] = checkElement("szczyt");
    chosenElements["listwa-podokapowa"] = checkElement("listwa-podokapowa");
    this.setState({ threeD: !document.getElementById("threeD").checked });

    // Get elements for chosen location
    const THAT = this;
    let xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        let response = JSON.parse(`[${this.responseText.slice(0, -1)}]`);
        let elements = {};
        let chosenElements = {};
        for (let i = 0; i < response.length; i++) {
          // Convert pozycja from text to array
          response[i].pozycja = JSON.parse(
            response[i].pozycja.replace(/'/g, '"')
          );
          response[i].obrot = response[i].obrot
            ? JSON.parse(response[i].obrot.replace(/'/g, '"'))
            : undefined;
          // Filter and sort all elements
          if (elements[response[i].typ]) {
            elements[response[i].typ].push(response[i]);
          } else {
            elements[response[i].typ] = response[i];
          }
          // Set default elements for every category (first element of every category)
          if (!chosenElements[response[i].typ]) {
            chosenElements[response[i].typ] = response[i];
          }
        }
        THAT.setState({ elements, chosenElements });
      }
    };
    let formData = new FormData();
    formData.append("table", "elementy");
    formData.append("columns", ["id", "typ", "nazwa", "pozycja", "obrot"]);
    formData.append("gmina", document.getElementById("miejscowosc").value);
    xmlhttp.open(
      "POST",
      "https://architektura.podlasie.org/php-queries/public/projekt-query.php",
      true
    );
    xmlhttp.send(formData);
  };

  zoom = 1;

  zoomImage = e => {
    let current = document.getElementById("projekt-image").style.width || "60%";
    if (parseInt(current) < 60) current = 60;
    else if (parseInt(current) > 100) current = 100;
    if (e.deltaY < 0) {
      document.getElementById("projekt-image").style.width = `${parseInt(
        current
      ) + 10}%`;
    } else if (e.deltaY > 0) {
      document.getElementById("projekt-image").style.width = `${parseInt(
        current
      ) - 10}%`;
    }
  };

  startY;
  startX;
  endY = 0;
  endX = 0;

  setStartPosition = e => {
    this.startY = e.pageY;
    this.startX = e.pageX;
  };

  moveImage = e => {
    if (!e.pageY || !e.pageX) return null;
    document.getElementById("projekt-image").style.transform = `translate(${this
      .endX +
      e.pageX -
      this.startX}px, ${this.endY + e.pageY - this.startY}px)`;
  };

  setEndPosition = e => {
    this.endY = e.pageY - this.startY + this.endY;
    this.endX = e.pageX - this.startX + this.endX;
  };

  checkElements = () => {
    let maxLength = 0;
    // Get the longest array in elements array
    for (let i = 0; i < this.state.elements.length; i++) {
      if (maxLength < this.state.elements[i].length) {
        maxLength = this.state.elements[i].length;
      }
    }
    // if length === 1 (or less), don't show the array (<select>)
    return maxLength < 2 ? false : true;
  };

  printProject = () => {
    var a = window.open("", "", "height=500, width=500");
    a.document.write("<html>");
    a.document.write("<body >");
    a.document.write(`
    <img
      src="https://architektura.podlasie.org/projekt/${this.props.miejscowosc}/dom-front.svg"}
      alt="Projekt"
      style="height: 100%; object-fit: cover;"
    />`);
    a.document.write("</body></html>");
    a.document.close();
    a.print();
  };

  render() {
    return (
      <div id="projekt-house-model">
        <div id="phm-loading"></div>
        <div
          id="phm-options"
          style={{
            transform: this.state.openModal
              ? "translateX(0px)"
              : "translateX(-350px)"
          }}
        >
          <div className="phm-options-section">
            <div className="phm-options-title">Wybierz miejscowość</div>
            <select
              name="miejscowosc"
              id="miejscowosc"
              className="projekt-select"
              style={{ marginLeft: "12px" }}
            >
              <option value="bialowieza">Białowieża</option>
              <option value="czyze">Czyże</option>
              <option value="dobrowoda">Dobrowoda</option>
              <option value="grabowiec">Grabowiec</option>
              <option value="nowoberezowo">Nowoberezowo</option>
              <option value="olchowka">Olchówka</option>
              <option value="plutycze">Plutycze</option>
              <option value="puchly">Puchły</option>
              <option value="soce">Soce</option>
              <option value="szernie">Szernie</option>
              <option value="zubacze">Zubacze</option>
            </select>
          </div>
          {this.checkElements() ? (
            <div className="phm-options-section">
              <div className="phm-options-title" style={{ marginTop: "24px" }}>
                Elementy
              </div>
              {/* Okno */}
              {this.state.elements.okno.length > 1 ? (
                <React.Fragment>
                  <label htmlFor="okno">Okno</label>
                  <select
                    name="okno"
                    id="okno"
                    className="projekt-select"
                    style={{ marginLeft: "12px" }}
                  >
                    {this.state.elements.okno.map(okno => {
                      return <option value={okno.id}>{okno.id}</option>;
                    })}
                  </select>
                </React.Fragment>
              ) : null}
              {/* Narożnik */}
              {this.state.elements.naroznik.length > 1 ? (
                <React.Fragment>
                  <label htmlFor="naroznik">Narożnik</label>
                  <select
                    name="naroznik"
                    id="naroznik"
                    className="projekt-select"
                    style={{ marginLeft: "12px" }}
                  >
                    {this.state.elements.naroznik.map(naroznik => {
                      return <option value={naroznik.id}>{naroznik.id}</option>;
                    })}
                  </select>
                </React.Fragment>
              ) : null}
              {/* Listwa podokapowa */}
              {this.state.elements["listwa-podokapowa"].length > 1 ? (
                <React.Fragment>
                  <label htmlFor="listwa-podokapowa">Listwa podokapowa</label>
                  <select
                    name="listwa-podokapowa"
                    id="listwa-podokapowa"
                    className="projekt-select"
                    style={{ marginLeft: "12px" }}
                  >
                    {this.state.elements["listwa-podokapowa"].map(listwa => {
                      return <option value={listwa.id}>{listwa.id}</option>;
                    })}
                  </select>
                </React.Fragment>
              ) : null}
              {/* Szczyt */}
              {this.state.elements.szczyt.length > 1 ? (
                <React.Fragment>
                  <label htmlFor="szczyt">Szczyt</label>
                  <select
                    name="szczyt"
                    id="szczyt"
                    className="projekt-select"
                    style={{ marginLeft: "12px" }}
                  >
                    {this.state.elements.szczyt.map(szczyt => {
                      return <option value={szczyt.id}>{szczyt.id}</option>;
                    })}
                  </select>
                </React.Fragment>
              ) : null}
              {/* Wiatrownica */}
              {this.state.elements.wiatrownica.length > 1 ? (
                <React.Fragment>
                  <label htmlFor="wiatrownica">Wiatrownica</label>
                  <select
                    name="wiatrownica"
                    id="wiatrownica"
                    className="projekt-select"
                    style={{ marginLeft: "12px" }}
                  >
                    {this.state.elements.okno.map(wiatrownica => {
                      return (
                        <option value={wiatrownica.id}>{wiatrownica.id}</option>
                      );
                    })}
                  </select>
                </React.Fragment>
              ) : null}
            </div>
          ) : null}
          {/* Kolory */}
          <div className="phm-options-section" style={{ paddingRight: "0px" }}>
            <div className="phm-options-title">Kolor</div>
            <div id="phm-options-colors-grid">
              <div
                className="phm-options-color"
                style={{ background: "#f5a369" }}
                onClick={this.setColor}
              ></div>
              <div
                className="phm-options-color"
                style={{ background: "#7d4f2e" }}
                onClick={this.setColor}
              ></div>
              <div
                className="phm-options-color"
                style={{ background: "#381d0a" }}
                onClick={this.setColor}
              ></div>
              <div
                className="phm-options-color"
                style={{ background: "#d48026" }}
                onClick={this.setColor}
              ></div>
              <div
                className="phm-options-color"
                style={{
                  background: "#ffffff",
                  width: "36px",
                  height: "36px",
                  borderWidth: "2px"
                }}
                onClick={this.setColor}
              ></div>
              {/* <div className="phm-options-color"></div>
              <div className="phm-options-color"></div>
              <div className="phm-options-color"></div>
              <div className="phm-options-color"></div>
              <div className="phm-options-color"></div> */}
            </div>
          </div>
          <div className="phm-options-section">
            <div className="phm-options-title">Ustawienia</div>
            <div
              style={{ marginLeft: "12px" }}
              className="objects-filters-rodzaj-body"
            >
              <input type="checkbox" id="threeD" />
              <label htmlFor="threeD" style={{ marginLeft: "0" }}>
                2D
              </label>
            </div>
          </div>
          <button
            className="projekt-button"
            onClick={this.printProject}
            style={{ marginBottom: "70px" }}
          >
            DRUKUJ
          </button>
          <button className="projekt-button" onClick={this.saveChanges}>
            ZAPISZ
          </button>
          <button
            id="phm-options-button"
            onClick={this.toggleModal}
            title={this.state.openModal ? "Zamknij" : "Otwórz opcje"}
          >
            <div
              id="phm-options-button-background"
              style={{
                transformOrigin: this.state.openModal ? "" : "left"
              }}
            ></div>
            <div
              id="phm-options-button-arrow"
              style={{
                transform: this.state.openModal ? "" : "rotate(135deg)",
                marginLeft: this.state.openModal ? "" : "0"
              }}
            ></div>
          </button>
        </div>
        {this.state.threeD ? (
          <div
            id="house-model"
            style={{
              transform: this.state.openModal
                ? "translateX(175px)"
                : "translateX(0)"
            }}
          ></div>
        ) : (
          <div
            id="house-project"
            style={{
              transform: this.state.openModal
                ? "translateX(-175px)"
                : "translateX(-350px)"
            }}
            onWheel={this.zoomImage}
          >
            <img
              src={`https://architektura.podlasie.org/projekt/${this.props.miejscowosc}/dom-front.svg`}
              alt="Projekt"
              id="projekt-image"
              onDragStart={this.setStartPosition}
              onDragEnd={this.setEndPosition}
              onDrag={this.moveImage}
            />
          </div>
        )}
      </div>
    );
  }
}
