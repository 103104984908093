import React, { Component } from "react";
import MetaTags from "react-meta-tags";

import "./Projekt.css";
import HouseModel from "./HouseModel";

export default class Projekt extends Component {
  state = {
    screen: 1,
    miejscowosc: "bialowieza"
  };

  componentDidMount() {
    if (window.innerWidth > 1120) {
      document.getElementById("title-bar").parentElement.style.transform =
        "translate(0, -50px)";
    }
  }

  componentWillUnmount() {
    if (window.innerWidth > 1120) {
      document.getElementById("title-bar").parentElement.style.transform = "";
    }
  }

  nextScreen = () => {
    this.setState({ screen: this.state.screen + 1 });
  };

  changeMiejscowosc = miejscowosc => {
    this.setState({ miejscowosc });
  };

  render() {
    return (
      <div id="projekt-body">
        <MetaTags>
          <title>Projekt Domu | Drewniane Podlasie</title>
          <meta
            id="meta-description"
            name="description"
            content="Zaprojektuj swój dom zgodnie z tradycyjnymi zasadami wzornictwa w różnych regionach podlasia."
          />
        </MetaTags>
        {this.state.screen === 1 ? (
          <div id="projekt-setup-location">
            <h2>Wybierz miejscowość</h2>
            <select
              name="miejscowosc"
              id="miejscowosc"
              className="projekt-select"
              value={this.state.miejscowosc}
              onChange={e => {
                this.changeMiejscowosc(e.target.value);
              }}
            >
              <option value="bialowieza">Białowieża</option>
              <option value="czyze">Czyże</option>
              <option value="dobrowoda">Dobrowoda</option>
              <option value="grabowiec">Grabowiec</option>
              <option value="nowoberezowo">Nowoberezowo</option>
              <option value="olchowka">Olchówka</option>
              <option value="plutycze">Plutycze</option>
              <option value="puchly">Puchły</option>
              <option value="soce">Soce</option>
              <option value="szernie">Szernie</option>
              <option value="zubacze">Zubacze</option>
            </select>
            <button className="projekt-button" onClick={this.nextScreen}>
              DALEJ
            </button>
          </div>
        ) : (
          <HouseModel
            prevScreen={this.prevScreen}
            miejscowosc={this.state.miejscowosc}
            changeMiejscowosc={this.changeMiejscowosc}
          />
        )}
      </div>
    );
  }
}
