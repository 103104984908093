import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ReCaptcha } from "react-recaptcha-google";

import "./Home.css";
import NewsTile from "../../elements/news-tile/NewsTile";

import { ReactComponent as ArrowIcon } from "../../images/icons/arrow.svg";
import { ReactComponent as EyeIcon } from "../../images/icons/eye.svg";
import { ReactComponent as BudynekGospodarczy } from "../../images/icons/budynek-gospodarczy.svg";
import { ReactComponent as Cerkiew } from "../../images/icons/cerkiew.svg";
import { ReactComponent as MalaArchitektura } from "../../images/icons/mala-architektura.svg";
import { ReactComponent as Kosciol } from "../../images/icons/kosciol.svg";
import { ReactComponent as BudynekMieszkalny } from "../../images/icons/budynek-mieszkalny.svg";
import { ReactComponent as Cmentarz } from "../../images/icons/cmentarz.svg";
import { ReactComponent as UkladPrzestrzenny } from "../../images/icons/uklad-przestrzenny.svg";
import { ReactComponent as BudynekUzytecznosciPublicznej } from "../../images/icons/budynek-uzytecznosci-publicznej.svg";
import { ReactComponent as Skanseny } from "../../images/icons/skanseny.svg";
import { ReactComponent as RejestrZabytkow } from "../../images/icons/rejestr-zabytkow.svg";
import { ReactComponent as EwidencjaZabytkow } from "../../images/icons/ewidencja-zabytkow.svg";
import { ReactComponent as Restauracja } from "../../images/icons/restauracja.svg";
import { ReactComponent as Nocleg } from "../../images/icons/nocleg.svg";
import { ReactComponent as Szlaki } from "../../images/icons/szlaki.svg";
import { ReactComponent as SendIcon } from "../../images/icons/send.svg";

export default class Home extends Component {
  constructor(props, context) {
    super(props, context);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  state = {
    aktualnosci: [],
    aktualnoscIndex: 0,
    emailSent: false,
    newsColor: "",
    newsTextColor: "",
    recaptchaToken: "",
  };

  componentDidMount() {
    try {
      if (this.captchaDemo) {
        this.captchaDemo.reset();
      }
    } catch {
      console.log("ReCaptcha hasn't been loaded.");
    }

    let xmlhttp = new XMLHttpRequest();
    const THAT = this;
    xmlhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        let aktualnosci = JSON.parse(`[${this.responseText.slice(0, -1)}]`);
        for (let i = 0; i < aktualnosci.length; i++) {
          aktualnosci[i].zdjecia = aktualnosci[i].zdjecia.split(",");
        }

        // Make first image fade in onload
        setTimeout(() => {
          let theImage = document
            .getElementById("home-slider-image")
            .getElementsByTagName("img")[0];
          theImage.style.opacity = "0";
          theImage.src = `https://architektura.podlasie.org/${
            aktualnosci[0] ? aktualnosci[0].zdjecia[0] : ""
          }`;

          let miniImg = new Image();
          miniImg.src = `https://architektura.podlasie.org/${
            aktualnosci[0] ? aktualnosci[0].zdjecia[0] : ""
          }`;
          miniImg.onload = () => {
            theImage.style.opacity = "1";
            THAT.setBackground(0);
          };

          let img = new Image();
          img.src = `https://architektura.podlasie.org/${
            aktualnosci[0] ? aktualnosci[0].zdjecia[1] : ""
          }`;
          img.onload = () => {
            theImage.src = `https://architektura.podlasie.org/${
              aktualnosci[0] ? aktualnosci[0].zdjecia[1] : ""
            }`;
          };
        }, 0);

        THAT.setState({ aktualnosci });
      }
    };
    let formData = new FormData();
    formData.append("table", "aktualnosci");
    formData.append("columns", ["id", "tytul", "zdjecia", "data", "kolor"]);
    formData.append("limit", 4);
    formData.append("reverse", true);
    xmlhttp.open(
      "POST",
      "https://architektura.podlasie.org/php-queries/public/public-query.php",
      true
    );
    xmlhttp.send(formData);
  }

  onLoadRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
    }
  }

  verifyCallback(recaptchaToken) {
    this.setState({ recaptchaToken });
  }

  changeAktualnosc = (number) => {
    if (this.state.aktualnoscIndex + number < 0) {
      this.setBackground(this.state.aktualnosci.length - 1);
    } else if (
      this.state.aktualnoscIndex + number >=
      this.state.aktualnosci.length
    ) {
      this.setBackground(0);
    } else {
      this.setBackground(this.state.aktualnoscIndex + number);
    }
  };

  renderNewsImage = () => {
    let elements = [];

    for (let i = 0; i < this.state.aktualnosci.length; i++) {
      elements.push(
        <div>
          <img
            src={`https://architektura.podlasie.org/${
              this.state.aktualnosci[i]
                ? this.state.aktualnosci[i].zdjecia[1]
                : ""
            }`}
            alt=""
            style={{
              opacity: i === this.state.aktualnoscIndex ? "1" : "0",
              zIndex: i === this.state.aktualnoscIndex ? "1" : "0",
              transition:
                i === this.state.aktualnoscIndex ? "" : "opacity .3s .3s",
            }}
          />
        </div>
      );
    }

    return elements;
  };

  setBackground = (index) => {
    // Check if the news color is set (in db), use it if it is
    if (this.state.aktualnosci[index].kolor) {
      const kolor = this.state.aktualnosci[index].kolor;
      let newsTextColor = "";
      if (
        contrast(
          [255, 255, 255],
          [parseInt(kolor, 16), parseInt(kolor, 16), parseInt(kolor, 16)]
        ) < 5
      ) {
        newsTextColor = "#000000";
      } else {
        newsTextColor = "#ffffff";
      }

      this.setState({
        aktualnoscIndex: index,
        newsColor: kolor,
        newsTextColor,
      });
      return;
    }

    // Get leading color from image
    var rgb = getAverageRGB(document.getElementsByTagName("img")[index]);

    // Check contrast to text
    function luminanace(r, g, b) {
      var a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
      });
      return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    }

    function contrast(rgb1, rgb2) {
      return (
        (luminanace(rgb1[0], rgb1[1], rgb1[2]) + 0.05) /
        (luminanace(rgb2[0], rgb2[1], rgb2[2]) + 0.05)
      );
    }

    let newsTextColor = "";
    if (contrast([255, 255, 255], [rgb.r, rgb.g, rgb.b]) < 5) {
      newsTextColor = "#000000";
    } else {
      newsTextColor = "#ffffff";
    }

    this.setState({
      aktualnoscIndex: index,
      newsColor: "rgb(" + rgb.r + "," + rgb.g + "," + rgb.b + ")",
      newsTextColor,
    });

    function getAverageRGB(imgEl) {
      var blockSize = 5, // only visit every 5 pixels
        defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
        canvas = document.createElement("canvas"),
        context = canvas.getContext && canvas.getContext("2d"),
        data,
        width,
        height,
        i = -4,
        length,
        rgb = { r: 0, g: 0, b: 0 },
        count = 0;

      if (!context) {
        return defaultRGB;
      }

      height = canvas.height =
        imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
      width = canvas.width =
        imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

      context.drawImage(imgEl, 0, 0);

      try {
        data = context.getImageData(0, 0, width, height);
      } catch (e) {
        return defaultRGB;
      }

      length = data.data.length;

      while ((i += blockSize * 4) < length) {
        ++count;
        rgb.r += data.data[i];
        rgb.g += data.data[i + 1];
        rgb.b += data.data[i + 2];
      }

      // ~~ used to floor values
      rgb.r = ~~(rgb.r / count);
      rgb.g = ~~(rgb.g / count);
      rgb.b = ~~(rgb.b / count);

      return rgb;
    }
  };

  sendMail = () => {
    if (this.state.recaptchaToken) {
      // Prepare and send email
      let message = `${document.getElementById("wiadomosc").value}

Nadawca: ${document.getElementById("imie").value}, ${
        document.getElementById("email").value
      }`;

      const THAT = this;
      let xmlhttp = new XMLHttpRequest();
      xmlhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          if (this.responseText === "true") {
            // Frontend response - animations, email sent
            document.getElementById("wiadomosc").style.transition = ".3s";
            document.getElementById("wiadomosc").style.height = "108px";
            document.getElementById(
              "home-contact-form-captcha-wrapper"
            ).style.border = "";
            THAT.setState({ emailSent: true });
          } else {
            document.getElementById(
              "home-contact-form-captcha-wrapper"
            ).style.border = "2px solid red";
          }
        }
      };
      let formData = new FormData();
      formData.append("subject", document.getElementById("temat").value);
      formData.append("message", message);
      formData.append("g-recaptcha-response", this.state.recaptchaToken);
      xmlhttp.open(
        "POST",
        "https://architektura.podlasie.org/php-queries/public/send-mail.php",
        true
      );
      xmlhttp.send(formData);
    } else {
      document.getElementById(
        "home-contact-form-captcha-wrapper"
      ).style.border = "2px solid red";
    }
  };

  render() {
    return (
      <div id="content" style={{ padding: "0" }}>
        <div id="home-slider">
          <div
            id="home-slider-title"
            style={{ background: this.state.newsColor, transition: ".3s" }}
          >
            <h1 style={{ color: this.state.newsTextColor, transition: ".3s" }}>
              {this.state.aktualnosci[this.state.aktualnoscIndex]
                ? this.state.aktualnosci[this.state.aktualnoscIndex].tytul
                : ""}
            </h1>
            <div id="home-slider-title-buttons">
              <Link
                to={`/aktualnosc/${
                  this.state.aktualnosci[this.state.aktualnoscIndex]
                    ? this.state.aktualnosci[this.state.aktualnoscIndex].id
                    : ""
                }`}
                className="home-slider-title-button"
                style={{
                  color: this.state.newsTextColor,
                  borderColor: this.state.newsTextColor,
                  transition: ".3s",
                }}
              >
                <div>Więcej</div>
                <EyeIcon
                  style={{ fill: this.state.newsTextColor, transition: ".3s" }}
                />
              </Link>
            </div>
          </div>
          <div id="home-slider-image">
            {this.renderNewsImage()}
            <button
              className="home-slider-button"
              onClick={() => {
                this.changeAktualnosc(-1);
              }}
              title="Poprzedni news"
            >
              <ArrowIcon />
            </button>
            <button
              className="home-slider-button"
              onClick={() => {
                this.changeAktualnosc(1);
              }}
              title="Następny news"
            >
              <ArrowIcon />
            </button>
          </div>
        </div>
        <div id="home-objects">
          <div>
            <b>Nadzwyczajne dziedzictwo.</b> Odwiedź ciekawe miejsca
          </div>
          <div id="home-objects-grid">
            <Link
              to="/obiekty/budynek-gospodarczy"
              className="home-object-tile"
            >
              <BudynekGospodarczy />
              <div className="home-object-tile-label">Budynek gospodarczy</div>
            </Link>
            <Link to="/obiekty/cerkiew" className="home-object-tile">
              <Cerkiew />
              <div className="home-object-tile-label">Cerkiew</div>
            </Link>
            <Link to="/obiekty/mala-architektura" className="home-object-tile">
              <MalaArchitektura />
              <div className="home-object-tile-label">Mała architektura</div>
            </Link>
            <Link to="/obiekty/kosciol" className="home-object-tile">
              <Kosciol />
              <div className="home-object-tile-label">Kościół</div>
            </Link>
            <Link to="/obiekty/budynek-mieszkalny" className="home-object-tile">
              <BudynekMieszkalny />
              <div className="home-object-tile-label">Budynek mieszkalny</div>
            </Link>
            <Link to="/obiekty/cmentarz" className="home-object-tile">
              <Cmentarz />
              <div className="home-object-tile-label">Cmentarz</div>
            </Link>
            <Link to="/obiekty/uklad-przestrzenny" className="home-object-tile">
              <UkladPrzestrzenny />
              <div className="home-object-tile-label">Układ przestrzenny</div>
            </Link>
            <Link
              to="/obiekty/budynek-uzytecznosci-publicznej"
              className="home-object-tile"
            >
              <BudynekUzytecznosciPublicznej />
              <div className="home-object-tile-label">
                Bud. użyteczności publicznej
              </div>
            </Link>
            <Link to="/obiekty/skanseny" className="home-object-tile">
              <Skanseny />
              <div className="home-object-tile-label">Skanseny i zagrody</div>
            </Link>
            <Link to="/obiekty/rejestr-zabytkow" className="home-object-tile">
              <RejestrZabytkow />
              <div className="home-object-tile-label">
                Obiekt wpisany do rejestru zabytków
              </div>
            </Link>
            <Link
              to="/obiekty/wojewodzka-ewidencja-zabytkow"
              className="home-object-tile"
            >
              <EwidencjaZabytkow />
              <div className="home-object-tile-label">
                Obiekt wpisany do wojewódzkiej ewidencji zabytków
              </div>
            </Link>
            <Link to="/obiekty/zwiedz" className="home-object-tile">
              <EyeIcon />
              <div className="home-object-tile-label">Odwiedź / zwiedź</div>
            </Link>
            <Link to="/obiekty/restauracja" className="home-object-tile">
              <Restauracja />
              <div className="home-object-tile-label">Tutaj dobrze zjesz</div>
            </Link>
            <Link to="/obiekty/nocleg" className="home-object-tile">
              <Nocleg />
              <div className="home-object-tile-label">Przenocuj</div>
            </Link>
            <Link to="/obiekty/szlaki" className="home-object-tile">
              <Szlaki />
              <div className="home-object-tile-label">Szlaki</div>
            </Link>
          </div>
        </div>
        <div id="home-goto-map">
          <div id="home-goto-map-text">
            <div id="home-goto-map-label">
              Wędruj ścieżkami <br />
              drewnianego <br />
              Podlasia
            </div>
            <Link to="/mapa" id="home-goto-map-button">
              Przejdź do mapy
            </Link>
          </div>
          <img
            src={require("../../images/map-bg.webp")}
            alt="Projekt domu"
            id="map-background"
          />
        </div>
        <div id="home-news">
          <div id="home-news-title-row">
            <div>Aktualności</div>
            <Link to="/aktualnosci">Zobacz wszystko</Link>
          </div>
          <div id="home-news-grid">
            <NewsTile news={this.state.aktualnosci[0]} />
            <NewsTile news={this.state.aktualnosci[1]} />
            <NewsTile news={this.state.aktualnosci[2]} />
            <NewsTile news={this.state.aktualnosci[3]} />
          </div>
        </div>
        <div id="home-wybuduj-dom">
          <img
            src={require("../../images/rysunek-dom.webp")}
            alt="Projekt domu"
            id="home-wd-rysunek"
          />
          <img
            src={require("../../images/strug.webp")}
            alt="Strug"
            id="home-wd-strug"
          />
          <div id="home-wd-text">
            <div id="home-wd-label">
              Wybuduj <br /> tradycyjny, <br /> podlaski dom
            </div>
            <Link to="/projekt" id="home-wd-button">
              Czytaj więcej
            </Link>
          </div>
        </div>
        {/* <div id="home-instagram">
          <div className="title" style={{ color: "var(--accent-color)" }}>
            Instagram
          </div>
          <div id="home-instagram-photos">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div> */}
        <div
          style={{ width: "100%", height: "1px", background: "#e6e6e6" }}
        ></div>
        <div id="home-contact-form">
          <div id="home-contact-form-label">
            <b>Skontaktuj się z nami.</b> Napisz nam historię swojego domu
          </div>
          <div id="home-contact-form-grid">
            <div className="home-contact-form-input-wrapper">
              <input type="text" id="imie" />
              <label htmlFor="imie">Imię</label>
            </div>
            <div className="home-contact-form-input-wrapper">
              <input type="text" id="email" />
              <label htmlFor="email">E-mail</label>
            </div>
            <div className="home-contact-form-input-wrapper">
              <input type="text" id="temat" />
              <label htmlFor="temat">Temat</label>
            </div>
            <div className="home-contact-form-input-wrapper">
              <textarea id="wiadomosc" />
              <label htmlFor="wiadomosc">Wiadomość</label>
            </div>
            <div id="home-contact-form-captcha-wrapper">
              {window.grecaptcha && window.grecaptcha.render ? (
                <ReCaptcha
                  ref={(el) => {
                    this.captchaDemo = el;
                  }}
                  size="normal"
                  render="explicit"
                  sitekey="6Lfs6tEUAAAAALRyDP7KdKo3ffRyCV_AuQQf-7Nb"
                  onloadCallback={this.onLoadRecaptcha}
                  verifyCallback={this.verifyCallback}
                />
              ) : null}
            </div>
            <button id="home-contact-form-send" onClick={this.sendMail}>
              Wyślij wiadomość <SendIcon />
            </button>
            <div
              id="home-contact-form-email-sent"
              style={{ height: this.state.emailSent ? "100%" : "0" }}
            >
              <div
                style={{
                  top: this.state.emailSent ? "calc(50% - 25px)" : "25%",
                  opacity: this.state.emailSent ? "1" : "0",
                }}
              >
                E-mail został wysłany!
              </div>
              <SendIcon
                style={{
                  animation: this.state.emailSent ? "sentEmail 3s" : "",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
