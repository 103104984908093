import * as THREE from "three";

export const renderListwaPodokapowa = (scene, loader, fileParams) => {
  let listwaPodokapowaParams = [
    // Front
    { rotation: 0, repeat: fileParams.roofWidth },
    // Back
    { rotation: Math.PI, repeat: fileParams.roofWidth },
    // Left
    { rotation: -Math.PI / 2, repeat: fileParams.depth },
    // Right
    { rotation: Math.PI / 2, repeat: fileParams.depth }
  ];

  for (let j = 0; j < fileParams.nazwa.length; j++) {
    let pos =
      typeof fileParams.pozycja[0][0] === "object"
        ? fileParams.pozycja[j]
        : fileParams.pozycja;

    // load a resource
    loader.load(
      // resource URL
      `https://architektura.podlasie.org/projekt/${fileParams.gmina}/${fileParams.nazwa[j]}`,

      // onLoad callback
      function(texture) {
        for (let i = 0; i < pos.length; i++) {
          let repeat = listwaPodokapowaParams[i].repeat;
          // Olchowka has 3 [listwa-podokapowa] with different widths
          if (fileParams.gmina === "olchowka") {
            if (j === 1 && i < 2) {
              repeat = fileParams.roofWidth - 0.4;
            } else if (j === 2 && i < 2) {
              repeat = fileParams.roofWidth - 0.5;
            }
          }
          // Don't repeat for some (plutycze, ...), set width to image width
          let shouldRepeat = false;
          if (texture.image.width < 500) {
            shouldRepeat = true;
          }
          // Repeat texture on fixed distance
          if (shouldRepeat) {
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            texture.repeat.set(repeat / (texture.image.width / 1000), 1);
          }
          let material = new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true
          });
          let geometry = new THREE.PlaneGeometry(
            shouldRepeat ? repeat : texture.image.width / 1000,
            texture.image.height / 1000,
            1,
            1
          );
          let okno = new THREE.Mesh(geometry, material);

          okno.position.set(...pos[i]);
          okno.rotation.y = listwaPodokapowaParams[i].rotation;
          scene.add(okno);
        }
      },

      // onProgress callback currently not supported
      undefined,

      // onError callback
      function(err) {
        console.error("An error happened. (listwa-podokapowa)");
      }
    );
  }
};
