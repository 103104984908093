import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MetaTags } from "react-meta-tags";

import "./Wykonawcy.css";

import { ReactComponent as MarkerIcon } from "../../images/icons/marker.svg";
import { ReactComponent as SpecIcon } from "../../images/icons/specjalnosc.svg";

export default class Wykonawcy extends Component {
  state = {
    wykonawcy: [],
    amount: 0,
    currentPage: 1,
  };

  componentDidMount() {
    const THAT = this;
    let xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        let wykonawcy = JSON.parse(`[${this.responseText.slice(0, -1)}]`);
        for (let i = 0; i < wykonawcy.length; i++) {
          wykonawcy[i].zdjecia = wykonawcy[i].zdjecia.split(",");
        }
        THAT.setState({ wykonawcy });
      }
    };
    let formData = new FormData();
    formData.append("table", "wykonawcy");
    formData.append("columns", [
      "id",
      "imie",
      "specjalnosc",
      "adres",
      "miejscowosc",
      "kod_pocztowy",
      "zdjecia",
    ]);
    formData.append("limit", 12);
    xmlhttp.open(
      "POST",
      "https://architektura.podlasie.org/php-queries/public/public-query.php",
      true
    );
    xmlhttp.send(formData);

    let xmlhttp2 = new XMLHttpRequest();
    xmlhttp2.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        let wykonawcy = JSON.parse(`[${this.responseText.slice(0, -1)}]`);
        THAT.setState({ amount: wykonawcy.length });
      }
    };
    let formData2 = new FormData();
    formData2.append("table", "wykonawcy");
    formData.append("columns", ["id"]);
    xmlhttp2.open(
      "POST",
      "https://architektura.podlasie.org/php-queries/public/public-query.php",
      true
    );
    xmlhttp2.send(formData2);
  }

  renderObjects = () => {
    let elements = [];

    for (let i = 0; i < this.state.wykonawcy.length; i++) {
      elements.push(
        <Link
          className="object-tile"
          to={`/wykonawca/${this.state.wykonawcy[i].id}`}
        >
          <div className="object-tile-image">
            <div>
              <img
                src={`https://architektura.podlasie.org/${this.state.wykonawcy[i].zdjecia[0]}`}
                alt="Wykonawca"
              />
            </div>
          </div>
          <div className="object-tile-name">{this.state.wykonawcy[i].imie}</div>
          <div className="crafter-tile-location">
            <MarkerIcon />
            <div>
              <div>{this.state.wykonawcy[i].adres}</div>
              <div>
                {this.state.wykonawcy[i].kod_pocztowy}{" "}
                {this.state.wykonawcy[i].miejscowosc}
              </div>
            </div>
          </div>
          <div className="crafter-tile-speciality">
            <SpecIcon />
            <div>{this.state.wykonawcy[i].specjalnosc}</div>
          </div>
        </Link>
      );
    }

    return elements;
  };

  changePage = (e) => {
    window.scrollTo(0, 0);
    this.setState({ wykonawcy: [], currentPage: parseInt(e.target.innerHTML) });
    const THAT = this;
    let xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        let wykonawcy = JSON.parse(`[${this.responseText.slice(0, -1)}]`);
        for (let i = 0; i < wykonawcy.length; i++) {
          wykonawcy[i].zdjecia = wykonawcy[i].zdjecia.split(",");
        }
        THAT.setState({ wykonawcy });
      }
    };
    let formData = new FormData();
    formData.append("table", "wykonawcy");
    formData.append("columns", [
      "id",
      "imie",
      "specjalnosc",
      "adres",
      "miejscowosc",
      "kod_pocztowy",
      "zdjecia",
    ]);
    formData.append("limit", 12);
    formData.append("limitOffset", (parseInt(e.target.innerHTML) - 1) * 12);
    xmlhttp.open(
      "POST",
      "https://architektura.podlasie.org/php-queries/public/public-query.php",
      true
    );
    xmlhttp.send(formData);
  };

  renderPageButtons = () => {
    let elements = [];
    const { currentPage } = this.state;
    const maxLength = Math.ceil(this.state.amount / 12);
    const isMaxLength = maxLength > 10;
    let isBreak = false;

    for (let i = 0; i < maxLength; i++) {
      if (
        (isMaxLength && currentPage <= 2 && i <= 2) ||
        (isMaxLength && maxLength - currentPage <= 2 && maxLength - i <= 3) ||
        (isMaxLength && currentPage - i >= 0 && currentPage - i <= 2) ||
        !isMaxLength ||
        i === 0 ||
        i + 1 === maxLength
      ) {
        isBreak = false;
        elements.push(
          <button
            className="square-button"
            key={i}
            onClick={i + 1 === currentPage ? null : this.changePage}
            style={{
              margin: "0 3px",
              background: i + 1 === currentPage ? "var(--secondary-color)" : "",
              color: i + 1 === currentPage ? "#ffffff" : "",
            }}
          >
            {i + 1}
          </button>
        );
      } else if (isBreak === false) {
        isBreak = true;
        elements.push(
          <div
            key={i}
            className="square-button"
            style={{
              margin: "0 3px",
              pointerEvents: "none",
              height: "38px",
              width: "38px",
            }}
          >
            <div className="square-button-dot"></div>
            <div className="square-button-dot"></div>
            <div className="square-button-dot"></div>
          </div>
        );
      }
    }

    return elements;
  };

  render() {
    return (
      <div id="content" style={{ paddingBottom: "120px" }}>
        <MetaTags>
          <title>Wykonawcy i Rzemieślnicy | Drewniane Podlasie</title>
          <meta
            id="meta-description"
            name="description"
            content="Znajdź odpowiednich rzemieślników, którzy pomogą Ci zaprojektować i zbudować tradycyjny podlaski dom marzeń."
          />
        </MetaTags>
        <div className="title title-top">Wykonawcy i Rzemieślnicy</div>
        {this.state.wykonawcy[0] ? (
          <div id="objects-content">
            <div id="object-grid">{this.renderObjects()}</div>
          </div>
        ) : (
          <div id="loading" title="Ładowanie">
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
        <div id="objects-button-row">
          {this.state.amount > 12 ? this.renderPageButtons() : null}
        </div>
      </div>
    );
  }
}
