import * as THREE from "three";

export const renderNaroznik = (scene, loader, fileParams) => {
  let rogParams = [
    // Front
    { rotation: 0, flip: 1 },
    { rotation: 0, flip: -1 },
    // Back
    { rotation: Math.PI, flip: -1 },
    { rotation: Math.PI, flip: 1 },
    // left
    { rotation: -Math.PI / 2, flip: -1 },
    { rotation: -Math.PI / 2, flip: 1 },
    // Right
    { rotation: Math.PI / 2, flip: 1 },
    { rotation: Math.PI / 2, flip: -1 }
  ];

  // load a resource
  loader.load(
    // resource URL
    `https://architektura.podlasie.org/projekt/${fileParams.gmina}/${fileParams.nazwa}`,

    // onLoad callback
    function(texture) {
      for (let i = 0; i < rogParams.length; i++) {
        let material = new THREE.MeshBasicMaterial({
          map: texture,
          transparent: true
        });
        let geometry = new THREE.PlaneGeometry(
          texture.image.width / 1000,
          texture.image.height / 1000,
          1,
          1
        );
        let okno = new THREE.Mesh(geometry, material);

        const scale = 1;
        okno.scale.set(rogParams[i].flip * scale, scale, scale);

        okno.position.set(...fileParams.pozycja[i]);
        okno.rotation.y = rogParams[i].rotation;
        scene.add(okno);
      }
    },

    // onProgress callback currently not supported
    undefined,

    // onError callback
    function(err) {
      console.error("An error happened. (rog)");
    }
  );
};
