import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

import "./Mapa.css";

class MapaMap extends Component {

  shouldComponentUpdate(nextProps) {
    if (nextProps.obiekty.toString() === this.props.obiekty.toString()) {
      return false;
    }
    return true;
  }

  onMarkerClick = (props, marker, e) => {
    this.props.onMarkerClick(props, marker, e)
  };

  renderMarkers = () => {
    let elements = [];

    for (let i = 0; i < this.props.obiekty.length; i++) {
      let obiekt = this.props.obiekty[i];
      elements.push(
        <Marker
          position={{
            lat: parseInt(obiekt.lat) / 10000000,
            lng: parseInt(obiekt.lon) / 10000000,
          }}
          onClick={this.onMarkerClick}
          name={obiekt.nazwa}
          id={obiekt.id}
        />
      );
    }

    return elements;
  };

  render() {
    return (
      <Map
        google={this.props.google}
        zoom={11.5}
        initialCenter={{ lat: 52.8627262, lng: 23.3999195 }}
      >
        {this.renderMarkers()}
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyB5GQymYsL1RX0uH9Qe0fAUv18Xn6tnpRY",
})(MapaMap);
