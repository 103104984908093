import * as THREE from "three";

export const renderSciana = (scene, loader, fileParams) => {
  let scianaParams = [
    { rotation: 0, width: fileParams.baseWidth },
    { rotation: Math.PI, width: fileParams.baseWidth },
    { rotation: Math.PI / 2, width: fileParams.depth },
    { rotation: -Math.PI / 2, width: fileParams.depth }
  ];
  // load a resource
  loader.load(
    // resource URL
    `https://architektura.podlasie.org/projekt/${fileParams.gmina}/${fileParams.nazwa}`,

    // onLoad callback
    function(texture) {
      for (let i = 0; i < fileParams.pozycja.length; i++) {
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        texture.repeat.set(
          scianaParams[i].width / (texture.image.width / 1000),
          1
        );
        let material = new THREE.MeshBasicMaterial({
          map: texture,
          transparent: true
        });
        let geometry = new THREE.PlaneGeometry(
          scianaParams[i].width,
          texture.image.height / 1000,
          1,
          1
        );
        let okno = new THREE.Mesh(geometry, material);

        okno.position.set(...fileParams.pozycja[i]);
        okno.rotation.y = scianaParams[i].rotation;
        scene.add(okno);
      }
    },

    // onProgress callback currently not supported
    undefined,

    // onError callback
    function(err) {
      console.error("An error happened. (sciana)");
    }
  );
};
