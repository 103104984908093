import * as THREE from "three";

export const renderSzczyt = (scene, loader, fileParams) => {
  let szczytParams = [{ rotation: 0 }, { rotation: Math.PI }];
  // load a resource
  loader.load(
    // resource URL
    `https://architektura.podlasie.org/projekt/${fileParams.gmina}/${fileParams.nazwa}`,

    // onLoad callback
    function(texture) {
      for (let i = 0; i < fileParams.pozycja.length; i++) {
        let material = new THREE.MeshBasicMaterial({
          map: texture,
          transparent: true
        });
        let geometry = new THREE.PlaneGeometry(
          texture.image.width / 1000,
          texture.image.height / 1000,
          1,
          1
        );
        let okno = new THREE.Mesh(geometry, material);

        okno.position.set(...fileParams.pozycja[i]);
        okno.rotation.y = szczytParams[i].rotation;
        scene.add(okno);
      }
    },

    // onProgress callback currently not supported
    undefined,

    // onError callback
    function(err) {
      console.error("An error happened. (szczyt)");
    }
  );
};

export const renderSzczytOkno = (scene, loader, fileParams) => {
  let szczytOknoParams =
    fileParams.pozycja.length > 2
      ? [
          { rotation: 0 },
          { rotation: 0 },
          { rotation: Math.PI },
          { rotation: Math.PI }
        ]
      : [{ rotation: 0 }, { rotation: Math.PI }];

  // load a resource
  loader.load(
    // resource URL
    `https://architektura.podlasie.org/projekt/${fileParams.gmina}/${fileParams.nazwa}`,

    // onLoad callback
    function(texture) {
      for (let i = 0; i < fileParams.pozycja.length; i++) {
        let material = new THREE.MeshBasicMaterial({
          map: texture,
          transparent: true
        });
        let geometry = new THREE.PlaneGeometry(
          texture.image.width / 1000,
          texture.image.height / 1000,
          1,
          1
        );
        let okno = new THREE.Mesh(geometry, material);

        okno.position.set(...fileParams.pozycja[i]);
        okno.rotation.y = szczytOknoParams[i].rotation;
        scene.add(okno);
      }
    },

    // onProgress callback currently not supported
    undefined,

    // onError callback
    function(err) {
      console.error("An error happened. (szczyt-okno)");
    }
  );
};

export const renderSzczytListwa = (scene, loader, fileParams) => {
  let szczytBelkaParams = [
    // Front
    { rotation: 0 },
    // Back
    { rotation: Math.PI }
  ];

  let widths = {
    dobrowoda: 1380,
    grabowiec: 1240,
    plutycze: 1278,
    soce: 1214,
    szernie: 1146,
    zubacze: 1297
  };

  // load a resource
  loader.load(
    // resource URL
    `https://architektura.podlasie.org/projekt/${fileParams.gmina}/${fileParams.nazwa}`,

    // onLoad callback
    function(texture) {
      let width = widths[fileParams.gmina] || texture.image.width;

      for (let i = 0; i < szczytBelkaParams.length; i++) {
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        texture.repeat.set(width / texture.image.width, 1);
        let material = new THREE.MeshBasicMaterial({
          map: texture,
          transparent: true
        });
        let geometry = new THREE.PlaneGeometry(
          width / 1000,
          texture.image.height / 1000,
          1,
          1
        );
        let okno = new THREE.Mesh(geometry, material);

        okno.position.set(...fileParams.pozycja[i]);
        okno.rotation.y = szczytBelkaParams[i].rotation;
        scene.add(okno);
      }
    },

    // onProgress callback currently not supported
    undefined,

    // onError callback
    function(err) {
      console.error("An error happened. (szczyt-belka)");
    }
  );
};

export const renderSzczytZdobienie = (scene, loader, fileParams) => {
  for (let j = 0; j < fileParams.nazwa.length; j++) {
    let pos =
      typeof fileParams.pozycja[0][0] === "object"
        ? fileParams.pozycja[j]
        : fileParams.pozycja;

    // load a resource
    loader.load(
      // resource URL
      `https://architektura.podlasie.org/projekt/${fileParams.gmina}/${fileParams.nazwa[j]}`,

      // onLoad callback
      function(texture) {
        for (let i = 0; i < pos.length; i++) {
          let material = new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true
          });
          let geometry = new THREE.PlaneGeometry(
            texture.image.width / 1000,
            texture.image.height / 1000,
            1,
            1
          );
          let okno = new THREE.Mesh(geometry, material);

          // There's 1 [ozdoba] that needs to be mirrored
          if (fileParams.gmina === "szernie" && i % 2 === 0) {
            okno.scale.x = -1;
          }

          okno.position.set(...pos[i]);
          okno.rotation.y = i >= pos.length / 2 ? Math.PI : 0;
          scene.add(okno);
        }
      },

      // onProgress callback currently not supported
      undefined,

      // onError callback
      function(err) {
        console.error("An error happened. (szczyt-zdobienie)");
      }
    );
  }
};
