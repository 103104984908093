import React, { Component } from "react";
import { MetaTags } from "react-meta-tags";

import "./Obiekty.css";
import ObjectTile from "../../elements/object-tile/ObjectTile";

export default class Obiekty extends Component {
  state = {
    obiekty: [],
    amount: 0,
    currentPage: 1,
    showFilters: false,
    isLoading: true,
  };

  componentDidMount() {
    let input = document.getElementById(this.props.match.params.filter);
    if (this.props.match.params.filter && input) {
      input.checked = true;
      this.filter();
      return null;
    }

    let xmlhttp = new XMLHttpRequest();
    const THAT = this;
    xmlhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        let obiekty = JSON.parse(`[${this.responseText.slice(0, -1)}]`);
        for (let i = 0; i < obiekty.length; i++) {
          obiekty[i].zdjecia = obiekty[i].zdjecia.split(",");
        }
        THAT.setState({ obiekty, isLoading: false });
      }
    };
    let formData = new FormData();
    formData.append("table", "obiekty");
    formData.append("columns", ["id", "nazwa", "miejscowosc", "zdjecia"]);
    formData.append("limit", 12);
    xmlhttp.open(
      "POST",
      "https://architektura.podlasie.org/php-queries/public/public-query.php",
      true
    );
    xmlhttp.send(formData);

    // Get the amount of objects
    let xmlhttp2 = new XMLHttpRequest();
    xmlhttp2.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        let obiekty = JSON.parse(`[${this.responseText.slice(0, -1)}]`);
        THAT.setState({ amount: obiekty.length, isLoading: false });
      }
    };
    let formData2 = new FormData();
    formData2.append("table", "obiekty");
    xmlhttp2.open(
      "POST",
      "https://architektura.podlasie.org/php-queries/public/public-query.php",
      true
    );
    xmlhttp2.send(formData2);
  }

  renderObjects = () => {
    let elements = [];

    for (let i = 0; i < this.state.obiekty.length; i++) {
      elements.push(<ObjectTile key={i} object={this.state.obiekty[i]} />);
    }

    return elements;
  };

  changePage = (e) => {
    window.scrollTo(0, 0);
    this.setState({
      obiekty: [],
      currentPage: parseInt(e.target.innerHTML),
      isLoading: true,
    });
    const THAT = this;
    let xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        let obiekty = JSON.parse(`[${this.responseText.slice(0, -1)}]`);
        for (let i = 0; i < obiekty.length; i++) {
          obiekty[i].zdjecia = obiekty[i].zdjecia.split(",");
        }
        THAT.setState({ obiekty, isLoading: false });
      }
    };
    let formData = new FormData();
    formData.append("table", "obiekty");
    formData.append("columns", ["id", "nazwa", "miejscowosc", "zdjecia"]);
    formData.append("limit", 12);
    formData.append("limitOffset", (parseInt(e.target.innerHTML) - 1) * 12);
    xmlhttp.open(
      "POST",
      "https://architektura.podlasie.org/php-queries/public/public-query.php",
      true
    );
    xmlhttp.send(formData);
  };

  renderPageButtons = () => {
    let elements = [];
    const { currentPage } = this.state;
    const maxLength = Math.ceil(this.state.amount / 12);
    const isMaxLength = maxLength > 10;
    let isBreak = false;

    for (let i = 0; i < maxLength; i++) {
      if (
        (isMaxLength && currentPage <= 2 && i <= 2) ||
        (isMaxLength && maxLength - currentPage <= 2 && maxLength - i <= 3) ||
        (isMaxLength && currentPage - i >= 0 && currentPage - i <= 2) ||
        !isMaxLength ||
        i === 0 ||
        i + 1 === maxLength
      ) {
        isBreak = false;
        elements.push(
          <button
            className="square-button"
            key={i}
            onClick={i + 1 === currentPage ? null : this.changePage}
            style={{
              margin: "0 3px",
              background: i + 1 === currentPage ? "var(--secondary-color)" : "",
              color: i + 1 === currentPage ? "#ffffff" : "",
            }}
          >
            {i + 1}
          </button>
        );
      } else if (isBreak === false) {
        isBreak = true;
        elements.push(
          <div
            key={i}
            className="square-button"
            style={{
              margin: "0 3px",
              pointerEvents: "none",
              height: "38px",
              width: "38px",
            }}
          >
            <div className="square-button-dot"></div>
            <div className="square-button-dot"></div>
            <div className="square-button-dot"></div>
          </div>
        );
      }
    }

    return elements;
  };

  filter = () => {
    this.setState({ isLoading: true });

    let rodzaj = [];
    if (document.getElementById("budynek-mieszkalny").checked)
      rodzaj.push("budynek mieszkalny");
    if (document.getElementById("budynek-gospodarczy").checked)
      rodzaj.push("budynek gospodarczy");
    if (document.getElementById("cmentarz").checked) rodzaj.push("cmentarz");
    if (document.getElementById("budynek-uzytecznosci-publicznej").checked)
      rodzaj.push("budynek uzytecznosci publicznej");
    if (document.getElementById("cerkiew").checked) rodzaj.push("cerkiew");
    if (document.getElementById("kosciol").checked) rodzaj.push("cerkiew");
    if (document.getElementById("mala-architektura").checked)
      rodzaj.push("mala architektura");
    if (document.getElementById("uklad-przestrzenny").checked)
      rodzaj.push("uklad przestrzenny");
    if (document.getElementById("skanseny").checked)
      rodzaj.push("skanseny i zagrody");

    let zabytek = [];
    if (document.getElementById("rejestr-zabytkow").checked)
      zabytek.push("rejestr zabytkow");
    if (document.getElementById("wojewodzka-ewidencja-zabytkow").checked)
      zabytek.push("wojewodzka ewidencja zabytkow");
    if (document.getElementById("gminna-ewidencja-zabytkow").checked)
      zabytek.push("gminna ewidencja zabytkow");
    if (document.getElementById("pomnik-historii").checked)
      zabytek.push("pomnik historii");

    let funkcja = [];
    if (document.getElementById("zwiedz").checked)
      funkcja.push("czy_zwiedzanie");
    if (document.getElementById("restauracja").checked)
      funkcja.push("czy_jedzenie");
    if (document.getElementById("nocleg").checked) funkcja.push("czy_nocleg");

    let xmlhttp = new XMLHttpRequest();
    const THAT = this;
    xmlhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        let obiekty = JSON.parse(`[${this.responseText.slice(0, -1)}]`);
        for (let i = 0; i < obiekty.length; i++) {
          obiekty[i].zdjecia = obiekty[i].zdjecia.split(",");
        }
        THAT.setState({ obiekty, isLoading: false });
      }
    };
    let formData = new FormData();
    formData.append("table", "obiekty");
    formData.append("columns", ["id", "nazwa", "miejscowosc", "zdjecia"]);
    formData.append("limit", 12);
    if (rodzaj.length) formData.append("rodzaj", rodzaj);
    if (funkcja.length) formData.append("funkcja", funkcja);
    if (zabytek.length) formData.append("zabytek", zabytek);
    if (document.getElementById("miejscowosc").value)
      formData.append(
        "miejscowosc",
        document.getElementById("miejscowosc").value
      );
    if (document.getElementById("gmina").value)
      formData.append("gmina", document.getElementById("gmina").value);
    xmlhttp.open(
      "POST",
      "https://architektura.podlasie.org/php-queries/public/public-query.php",
      true
    );
    xmlhttp.send(formData);

    let xmlhttp2 = new XMLHttpRequest();
    xmlhttp2.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        let obiekty = JSON.parse(`[${this.responseText.slice(0, -1)}]`);
        THAT.setState({ amount: obiekty.length, isLoading: false });
      }
    };
    let formData2 = new FormData();
    formData2.append("table", "obiekty");
    formData2.append("columns", ["id"]);
    if (rodzaj.length) formData2.append("rodzaj", rodzaj);
    if (funkcja.length) formData2.append("funkcja", funkcja);
    if (zabytek.length) formData2.append("zabytek", zabytek);
    if (document.getElementById("miejscowosc").value)
      formData2.append(
        "miejscowosc",
        document.getElementById("miejscowosc").value
      );
    if (document.getElementById("gmina").value)
      formData2.append("gmina", document.getElementById("gmina").value);
    xmlhttp2.open(
      "POST",
      "https://architektura.podlasie.org/php-queries/public/public-query.php",
      true
    );
    xmlhttp2.send(formData2);
  };

  render() {
    return (
      <div id="content" style={{ paddingBottom: "120px" }}>
        <MetaTags>
          <title>Obiekty | Drewniane Podlasie</title>
          <meta
            id="meta-description"
            name="description"
            content="Zbiór historycznych obiektów na podlasiu, które wyróżniają się wyjątkowymi tradycyjnymi zdobieniami i architekturą."
          />
        </MetaTags>
        <div id="objects-top-bar">
          <div className="title title-top" style={{ padding: "0" }}>
            Obiekty
          </div>
          <button
            className="objects-filters-button"
            onClick={() => {
              this.setState({ showFilters: !this.state.showFilters });
            }}
          >
            Filtry
          </button>
        </div>
        <div
          id="objects-filters-body"
          style={{
            display: this.state.showFilters ? "" : "none",
          }}
        >
          <div className="objects-filters-title">Rodzaj</div>
          <div className="objects-filters-rodzaj-body">
            <div>
              <input type="checkbox" id="budynek-mieszkalny" />
              <label htmlFor="budynek-mieszkalny">Budynek mieszkalny</label>
            </div>
            <div>
              <input type="checkbox" id="budynek-gospodarczy" />
              <label htmlFor="budynek-gospodarczy">Budynek gospodarczy</label>
            </div>
            <div>
              <input type="checkbox" id="cmentarz" />
              <label htmlFor="cmentarz">Cmentarz</label>
            </div>
            <div>
              <input type="checkbox" id="budynek-uzytecznosci-publicznej" />
              <label htmlFor="budynek-uzytecznosci-publicznej">
                Budynek użyteczności publicznej
              </label>
            </div>
            <div>
              <input type="checkbox" id="cerkiew" />
              <label htmlFor="cerkiew">Cerkiew</label>
            </div>
            <div>
              <input type="checkbox" id="kosciol" />
              <label htmlFor="kosciol">Kościół</label>
            </div>
            <div>
              <input type="checkbox" id="mala-architektura" />
              <label htmlFor="mala-architektura">Mała architektura</label>
            </div>
            <div>
              <input type="checkbox" id="uklad-przestrzenny" />
              <label htmlFor="uklad-przestrzenny">Układ przestrzenny</label>
            </div>
            <div>
              <input type="checkbox" id="skanseny" />
              <label htmlFor="skanseny">Skanseny i zagrody</label>
            </div>
          </div>
          <div className="objects-filters-title">Zabytki</div>
          <div className="objects-filters-rodzaj-body">
            <div>
              <input type="checkbox" id="rejestr-zabytkow" />
              <label htmlFor="rejestr-zabytkow">Rejestr zabytków</label>
            </div>
            <div>
              <input type="checkbox" id="wojewodzka-ewidencja-zabytkow" />
              <label htmlFor="wojewodzka-ewidencja-zabytkow">
                Wojewódzka ewidencja zabytków
              </label>
            </div>
            <div>
              <input type="checkbox" id="gminna-ewidencja-zabytkow" />
              <label htmlFor="gminna-ewidencja-zabytkow">
                Gminna ewidencja zabytków
              </label>
            </div>
            <div>
              <input type="checkbox" id="pomnik-historii" />
              <label htmlFor="pomnik-historii">Pomnik historii</label>
            </div>
          </div>
          <div className="objects-filters-title">Funkcja</div>
          <div className="objects-filters-rodzaj-body">
            <div>
              <input type="checkbox" id="zwiedz" />
              <label htmlFor="zwiedz">Odwiedź / Zwiedź</label>
            </div>
            <div>
              <input type="checkbox" id="restauracja" />
              <label htmlFor="restauracja">Tutaj dobrze zjesz</label>
            </div>
            <div>
              <input type="checkbox" id="nocleg" />
              <label htmlFor="nocleg">Przenocuj</label>
            </div>
          </div>
          <div className="objects-filters-title">Lokalizacja</div>
          <div className="home-contact-form-input-wrapper objects-filters-input">
            <input type="text" id="miejscowosc" />
            <label htmlFor="miejscowosc" style={{ background: "#f6f6f6" }}>
              Miejscowość
            </label>
          </div>
          <div className="home-contact-form-input-wrapper objects-filters-input">
            <input type="text" id="gmina" />
            <label htmlFor="gmina" style={{ background: "#f6f6f6" }}>
              Gmina
            </label>
          </div>
          <button
            className="objects-filters-button"
            id="objects-filters-filter-button"
            onClick={this.filter}
          >
            Zapisz
          </button>
        </div>
        {this.state.isLoading ? (
          <div id="loading" title="Ładowanie">
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : this.state.amount ? (
          <div id="objects-content">
            <div id="object-grid">{this.renderObjects()}</div>
          </div>
        ) : (
          <div id="loading">Brak obiektów</div>
        )}
        <div id="objects-button-row">
          {this.state.amount > 12 ? this.renderPageButtons() : null}
        </div>
      </div>
    );
  }
}
