import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import {
  renderWiatrownica,
  renderWiatrownicaZakonczenie
} from "./textures/wiatrownica";
import { renderListwaPodokapowa } from "./textures/listwa-podokapowa";
import {
  renderSzczyt,
  renderSzczytOkno,
  renderSzczytListwa,
  renderSzczytZdobienie
} from "./textures/szczyt";
import { renderNaroznik } from "./textures/naroznik-server";
import { renderOkno } from "./textures/okno-server";
import { renderSciana } from "./textures/sciana";

let cameraPos = [-2.75, 0.57, 5.05];

export const createHouseModel = (dimensions, chosenElements, color) => {
  let node = document.getElementById("house-model");
  if (!node) return null;
  node.innerHTML = "";

  // Set house dimensions
  let depth = 5;
  let baseHeight = parseInt(dimensions.wysokosc_podstawy) / 1000;
  let baseWidth = parseInt(dimensions.szerokosc_podstawy) / 1000;
  let roofHeight = parseInt(dimensions.wysokosc_dachu) / 1000;
  let roofWidth = parseInt(dimensions.szerokosc_dachu) / 1000;

  // Create scene and canvas in document
  let scene = new THREE.Scene();
  let camera = new THREE.PerspectiveCamera(
    75,
    (window.innerWidth - 50) / node.getBoundingClientRect().height,
    0.1,
    1000
  );
  camera.position.set(...cameraPos);
  let renderer = new THREE.WebGLRenderer();
  renderer.setSize(window.innerWidth - 50, node.getBoundingClientRect().height);
  node.appendChild(renderer.domElement);

  // Set background
  scene.background = new THREE.Color(0x69c3ff);
  // Grass
  let geometry = new THREE.PlaneGeometry(1000, 1000, 1, 1);
  let material = new THREE.MeshBasicMaterial({ color: 0x62bd3e });
  let floor = new THREE.Mesh(geometry, material);
  floor.rotation.x = -Math.PI / 2;
  floor.position.set(0, -baseHeight / 2, 0);
  scene.add(floor);
  // Create walls and roof

  // Walls (cube) - white
  geometry = new THREE.BoxGeometry(baseWidth, baseHeight, depth);
  material = new THREE.MeshBasicMaterial({ color });
  let cube = new THREE.Mesh(geometry, material);
  scene.add(cube);
  // Walls edges - black
  geometry = new THREE.BoxBufferGeometry(baseWidth, baseHeight, depth);
  let edges = new THREE.EdgesGeometry(geometry);
  let line = new THREE.LineSegments(
    edges,
    new THREE.LineBasicMaterial({ color: 0x000000 })
  );
  scene.add(line);

  // Roof - white
  geometry = new THREE.Geometry();
  // Create main points of roof
  geometry.vertices = [
    new THREE.Vector3(-roofWidth / 2, baseHeight / 2, depth / 2), // 0
    new THREE.Vector3(roofWidth / 2, baseHeight / 2, depth / 2), // 1
    new THREE.Vector3(-roofWidth / 2, baseHeight / 2, -depth / 2), // 2
    new THREE.Vector3(roofWidth / 2, baseHeight / 2, -depth / 2), // 3
    new THREE.Vector3(0, baseHeight / 2 + roofHeight, -depth / 2), // 4
    new THREE.Vector3(0, baseHeight / 2 + roofHeight, depth / 2) // 5
  ];
  // Create faces from vertices
  geometry.faces = [
    new THREE.Face3(0, 1, 5), // Front
    new THREE.Face3(2, 3, 4), // Back
    new THREE.Face3(3, 4, 5), // Right top
    new THREE.Face3(1, 3, 5), // Right bottom
    new THREE.Face3(0, 4, 5), // Left top
    new THREE.Face3(0, 2, 4) // Left bottom
  ];
  material = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    side: THREE.DoubleSide
  });
  let cylinder = new THREE.Mesh(geometry, material);
  scene.add(cylinder);
  // Roof edges - black
  geometry = new THREE.Geometry();
  // Create main points of roof
  geometry.vertices = [
    new THREE.Vector3(-roofWidth / 2, baseHeight / 2, depth / 2), // 0
    new THREE.Vector3(roofWidth / 2, baseHeight / 2, depth / 2), // 1
    new THREE.Vector3(-roofWidth / 2, baseHeight / 2, -depth / 2), // 2
    new THREE.Vector3(roofWidth / 2, baseHeight / 2, -depth / 2), // 3
    new THREE.Vector3(0, baseHeight / 2 + roofHeight, -depth / 2), // 4
    new THREE.Vector3(0, baseHeight / 2 + roofHeight, depth / 2) // 5
  ];
  // Create triangle edge faces from vertices (house front)
  geometry.faces = [
    new THREE.Face3(0, 1, 5), // Front
    new THREE.Face3(2, 3, 4) // Back
  ];

  material = new THREE.MeshBasicMaterial({ color, side: THREE.DoubleSide });
  cylinder = new THREE.Mesh(geometry, material);
  scene.add(cylinder);

  material = new THREE.LineBasicMaterial({
    color: 0x000000
  });
  // Ceiling (under the roof)
  geometry = new THREE.Geometry();
  // Create main points of roof
  geometry.vertices = [
    new THREE.Vector3(-roofWidth / 2, baseHeight / 2, depth / 2), // 0
    new THREE.Vector3(roofWidth / 2, baseHeight / 2, depth / 2), // 1
    new THREE.Vector3(-roofWidth / 2, baseHeight / 2, -depth / 2), // 2
    new THREE.Vector3(roofWidth / 2, baseHeight / 2, -depth / 2), // 3
    new THREE.Vector3(0, baseHeight / 2 + roofHeight, -depth / 2), // 4
    new THREE.Vector3(0, baseHeight / 2 + roofHeight, depth / 2) // 5
  ];
  geometry.faces = [new THREE.Face3(0, 1, 2), new THREE.Face3(1, 2, 3)];
  material = new THREE.MeshBasicMaterial({
    color,
    side: THREE.DoubleSide
  });
  cylinder = new THREE.Mesh(geometry, material);
  scene.add(cylinder);

  // Top roof edge
  geometry = new THREE.Geometry();
  geometry.vertices.push(
    new THREE.Vector3(0, baseHeight / 2 + roofHeight, depth / 2),
    new THREE.Vector3(0, baseHeight / 2 + roofHeight, -depth / 2)
  );
  material = new THREE.LineBasicMaterial({
    color: 0x000000
  });
  line = new THREE.Line(geometry, material);
  scene.add(line);
  // Left side roof edge
  geometry = new THREE.Geometry();
  geometry.vertices.push(
    new THREE.Vector3(-roofWidth / 2, baseHeight / 2, depth / 2),
    new THREE.Vector3(-roofWidth / 2, baseHeight / 2, -depth / 2)
  );
  line = new THREE.Line(geometry, material);
  scene.add(line);
  // Right side roof edge
  geometry = new THREE.Geometry();
  geometry.vertices.push(
    new THREE.Vector3(roofWidth / 2, baseHeight / 2, depth / 2),
    new THREE.Vector3(roofWidth / 2, baseHeight / 2, -depth / 2)
  );
  line = new THREE.Line(geometry, material);
  scene.add(line);

  // Load textures
  let loader = new THREE.TextureLoader();
  // Okno
  let fileParams = {
    gmina: dimensions.gmina,
    nazwa: chosenElements["okno"].nazwa,
    pozycja: chosenElements["okno"].pozycja
  };
  renderOkno(scene, loader, fileParams);
  // Naroznik
  fileParams = {
    gmina: dimensions.gmina,
    nazwa: chosenElements["naroznik"].nazwa,
    pozycja: chosenElements["naroznik"].pozycja
  };
  renderNaroznik(scene, loader, fileParams);
  // Szczyt
  if (chosenElements["szczyt"]) {
    fileParams = {
      gmina: dimensions.gmina,
      nazwa: chosenElements["szczyt"].nazwa,
      pozycja: chosenElements["szczyt"].pozycja
    };
    renderSzczyt(scene, loader, fileParams);
  }
  fileParams = {
    gmina: dimensions.gmina,
    nazwa: chosenElements["szczyt-okno"].nazwa,
    pozycja: chosenElements["szczyt-okno"].pozycja
  };
  renderSzczytOkno(scene, loader, fileParams);
  if (chosenElements["szczyt-listwa"]) {
    fileParams = {
      gmina: dimensions.gmina,
      nazwa: chosenElements["szczyt-listwa"].nazwa,
      pozycja: chosenElements["szczyt-listwa"].pozycja
    };
    renderSzczytListwa(scene, loader, fileParams);
  }
  if (chosenElements["szczyt-ozdoba"]) {
    fileParams = {
      gmina: dimensions.gmina,
      nazwa:
        chosenElements["szczyt-ozdoba"].nazwa.indexOf(",") === -1
          ? [chosenElements["szczyt-ozdoba"].nazwa]
          : JSON.parse(
              chosenElements["szczyt-ozdoba"].nazwa.replace(/'/g, '"')
            ),
      pozycja: chosenElements["szczyt-ozdoba"].pozycja
    };
    renderSzczytZdobienie(scene, loader, fileParams);
  }
  // Listwa podokapowa
  if (chosenElements["listwa-podokapowa"]) {
    fileParams = {
      gmina: dimensions.gmina,
      nazwa:
        chosenElements["listwa-podokapowa"].nazwa.indexOf(",") === -1
          ? [chosenElements["listwa-podokapowa"].nazwa]
          : JSON.parse(
              chosenElements["listwa-podokapowa"].nazwa.replace(/'/g, '"')
            ),
      pozycja: chosenElements["listwa-podokapowa"].pozycja,
      roofWidth,
      depth
    };
    renderListwaPodokapowa(scene, loader, fileParams);
  }
  // Wiatrownica
  if (chosenElements["wiatrownica"]) {
    fileParams = {
      gmina: dimensions.gmina,
      nazwa:
        chosenElements["wiatrownica"].nazwa.indexOf(",") === -1
          ? [chosenElements["wiatrownica"].nazwa]
          : JSON.parse(chosenElements["wiatrownica"].nazwa.replace(/'/g, '"')),
      pozycja: chosenElements["wiatrownica"].pozycja,
      obrot: chosenElements["wiatrownica"].obrot,
      roofHeight,
      roofWidth
    };
    renderWiatrownica(scene, loader, fileParams);
  }
  if (chosenElements["wiatrownica-zakonczenie"]) {
    fileParams = {
      gmina: dimensions.gmina,
      nazwa: chosenElements["wiatrownica-zakonczenie"].nazwa,
      pozycja: chosenElements["wiatrownica-zakonczenie"].pozycja,
      obrot: chosenElements["wiatrownica-zakonczenie"].obrot,
      roofHeight,
      roofWidth
    };
    renderWiatrownicaZakonczenie(scene, loader, fileParams);
  }
  // Sciana
  if (chosenElements["sciana"]) {
    fileParams = {
      gmina: dimensions.gmina,
      nazwa: chosenElements["sciana"].nazwa,
      pozycja: chosenElements["sciana"].pozycja,
      depth,
      baseWidth
    };
    renderSciana(scene, loader, fileParams);
  }

  // Initiate controls (mouse) and set limits (angle and zoom)
  let controls = new OrbitControls(camera, renderer.domElement);
  controls.maxPolarAngle = Math.PI / 2;
  controls.minDistance = 3.5;
  controls.maxDistance = 50;

  //controls.update() must be called after any manual changes to the camera's transform
  controls.update();

  let animate = function() {
    cameraPos = [camera.position.x, camera.position.y, camera.position.z];

    requestAnimationFrame(animate);
    // required if controls.enableDamping or controls.autoRotate are set to true
    controls.update();
    renderer.render(scene, camera);
  };
  animate();
};
