import * as THREE from "three";

export const renderWiatrownica = (scene, loader, fileParams) => {
  for (let j = 0; j < fileParams.nazwa.length; j++) {
    let pos =
      typeof fileParams.pozycja[0][0] === "object"
        ? fileParams.pozycja[j]
        : fileParams.pozycja;

    // load a resource
    loader.load(
      // resource URL
      `https://architektura.podlasie.org/projekt/${fileParams.gmina}/${fileParams.nazwa[j]}`,

      // onLoad callback
      function(texture) {
        for (let i = 0; i < pos.length; i++) {
          // Repeat texture on fixed distance
          texture.wrapS = THREE.RepeatWrapping;
          texture.wrapT = THREE.RepeatWrapping;
          texture.repeat.set(
            Math.sqrt(
              Math.pow(fileParams.roofHeight, 2) +
                Math.pow(fileParams.roofWidth / 2, 2)
            ) /
              (texture.image.width / 1000),
            1
          );
          let material = new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true
          });
          let geometry = new THREE.PlaneGeometry(
            Math.sqrt(
              Math.pow(fileParams.roofHeight, 2) +
                Math.pow(fileParams.roofWidth / 2, 2)
            ),
            texture.image.height / 1000,
            1,
            1
          );
          let okno = new THREE.Mesh(geometry, material);

          okno.position.set(...pos[i]);
          let rot =
            typeof fileParams.obrot[0][0] === "object"
              ? fileParams.obrot[j]
              : fileParams.obrot;
          okno.rotation.set(...rot[i]);
          scene.add(okno);
        }
      },

      // onProgress callback currently not supported
      undefined,

      // onError callback
      function(err) {
        console.error("An error happened. (wiatrownica)");
      }
    );
  }
};

export const renderWiatrownicaZakonczenie = (scene, loader, fileParams) => {
  // load a resource
  loader.load(
    // resource URL
    `https://architektura.podlasie.org/projekt/${fileParams.gmina}/${fileParams.nazwa}`,

    // onLoad callback
    function(texture) {
      for (let i = 0; i < fileParams.pozycja.length; i++) {
        let material = new THREE.MeshBasicMaterial({
          map: texture,
          transparent: true
        });
        let geometry = new THREE.PlaneGeometry(
          texture.image.width / 1000,
          texture.image.height / 1000,
          1,
          1
        );
        let okno = new THREE.Mesh(geometry, material);

        okno.scale.set(i % 2 ? -1 : 1, 1, 1);

        okno.position.set(...fileParams.pozycja[i]);
        okno.rotation.set(...fileParams.obrot[i]);
        scene.add(okno);
      }
    },

    // onProgress callback currently not supported
    undefined,

    // onError callback
    function(err) {
      console.error("An error happened. (wiatrownica)");
    }
  );
};
