import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MetaTags } from "react-meta-tags";

import "./Aktualnosc.css";
import { formatDate } from "../../elements/formatDate";

import { ReactComponent as ClockIcon } from "../../images/icons/clock.svg";
import { ReactComponent as GridIcon } from "../../images/icons/grid.svg";

export default class Aktualnosc extends Component {
  state = {
    aktualnosc: {},
  };

  componentDidMount() {
    let xmlhttp = new XMLHttpRequest();
    const THAT = this;
    xmlhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        // tempAktualnosci are in array, divided like JSON, but without quotes and braces
        // (JSON doesn't work because of new lines or hard enters or something)
        let tempAktualnosci = this.responseText
          // .slice(0, -17)
          .split(",RANDOMSTRING!@#,");
        // Convert tempAktualnosci to an array of objects (aktualnosci)
        let aktualnosci = [];
        for (let i = 0; i < Math.floor(tempAktualnosci.length / 10); i++) {
          aktualnosci.push({
            [tempAktualnosci[i * 10]]: tempAktualnosci[i * 10 + 1],
            [tempAktualnosci[i * 10 + 2]]: tempAktualnosci[i * 10 + 3],
            [tempAktualnosci[i * 10 + 4]]: tempAktualnosci[i * 10 + 5],
            [tempAktualnosci[i * 10 + 6]]: tempAktualnosci[i * 10 + 7],
            [tempAktualnosci[i * 10 + 8]]: tempAktualnosci[i * 10 + 9],
          });
        }
        // Convert zdjecia to array
        aktualnosci[0].zdjecia = aktualnosci[0].zdjecia.split(",");
        // Set opis

        setTimeout(() => {
          document.getElementById("a-content").innerHTML = aktualnosci[0].opis;

          // Set iframe height - if there are any
          const iframes = document.getElementsByTagName("iframe");
          for (let i = 0; i < iframes.length; i++) {
            const height =
              iframes[i].height && iframes[i].width
                ? parseInt(iframes[i].height)
                : 9;
            const width =
              iframes[i].height && iframes[i].width
                ? parseInt(iframes[i].width)
                : 16;
            iframes[i].style.height = `${
              (iframes[i].offsetWidth * height) / width
            }px`;
          }

          let theImage = document
            .getElementById("a-img")
            .getElementsByTagName("img")[0];
          theImage.style.opacity = "0";
          theImage.src = `https://architektura.podlasie.org/${
            aktualnosci[0] ? aktualnosci[0].zdjecia[0] : ""
          }`;

          let miniImg = new Image();
          miniImg.src = `https://architektura.podlasie.org/${
            aktualnosci[0] ? aktualnosci[0].zdjecia[0] : ""
          }`;
          miniImg.onload = () => {
            theImage.style.opacity = "1";
          };

          let img = new Image();
          img.src = `https://architektura.podlasie.org/${
            aktualnosci[0] ? aktualnosci[0].zdjecia[1] : ""
          }`;
          img.onload = () => {
            theImage.src = `https://architektura.podlasie.org/${
              aktualnosci[0] ? aktualnosci[0].zdjecia[1] : ""
            }`;
          };
        }, 0);
        // Set aktualnosc
        THAT.setState({ aktualnosc: { ...aktualnosci[0] } });
      }
    };
    let formData = new FormData();
    formData.append("table", "aktualnosci");
    formData.append("columns", ["id", "tytul", "opis", "data", "zdjecia"]);
    formData.append("id", this.props.match.params.id);
    xmlhttp.open(
      "POST",
      "https://architektura.podlasie.org/php-queries/public/aktualnosci-query.php",
      true
    );
    xmlhttp.send(formData);
  }

  render() {
    if (!this.state.aktualnosc.id) return null;

    return (
      <div
        id="content"
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <MetaTags>
          <title>{this.state.aktualnosc.tytul} | Drewniane Podlasie</title>
          <meta
            id="meta-description"
            name="description"
            content={this.state.aktualnosc.opis}
          />
        </MetaTags>
        <div id="a-img">
          <div>
            <img
              src={`https://architektura.podlasie.org/${this.state.aktualnosc.zdjecia[1]}`}
              alt=""
            />
          </div>
        </div>
        <div id="a-date">
          <ClockIcon />
          <div>{formatDate(this.state.aktualnosc.data)}</div>
        </div>
        <div id="a-title">{this.state.aktualnosc.tytul}</div>
        <div id="a-divider"></div>
        <div id="a-content"></div>
        <div id="a-buttons">
          <Link
            to="/aktualnosci"
            className="square-button"
            style={{
              borderColor: "var(--accent-color)",
              fill: "var(--accent-color)",
            }}
            title="Wszystkie aktualności"
          >
            <GridIcon />
          </Link>
        </div>
      </div>
    );
  }
}
