import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";

import "./Topbar.css";

import { ReactComponent as Logo } from "../../images/logo.svg";
import { ReactComponent as FacebookLogo } from "../../images/facebook.svg";
import { ReactComponent as InstagramLogo } from "../../images/instagram.svg";
import { ReactComponent as YoutubeLogo } from "../../images/youtube.svg";
import { ReactComponent as ProcentIcon } from "../../images/procent.svg";
import { ReactComponent as PhoneIcon } from "../../images/icons/phone.svg";
import { ReactComponent as MailIcon } from "../../images/icons/mail.svg";
import { ReactComponent as MarkerIcon } from "../../images/icons/marker.svg";

export default class Topbar extends Component {
  state = {
    menuOpen: false,
  };

  componentDidMount() {
    document.addEventListener(
      "scroll",
      () => {
        try {
          if (
            window.location.href.indexOf("mapa") === -1 &&
            window.location.href.indexOf("projekt") === -1 &&
            window.innerWidth > 1120
          ) {
            if (
              document.getElementsByTagName("body")[0].getBoundingClientRect()
                .top < -50
            ) {
              ReactDOM.findDOMNode(this).style.transform =
                "translate(0, -50px)";
            } else {
              ReactDOM.findDOMNode(this).style.transform = "";
            }
          }
        } catch {
          // Error
        }
      },
      { passive: true }
    );
  }

  toggleMenu = () => {
    if (!this.state.menuOpen) {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("body")[0].style.overflow = "";
    }
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  toggleKontakt = () => {
    this.setState({ showKontakt: !this.state.showKontakt });
    /* window.scrollBy({
      top: document.body.scrollHeight,
      left: 0,
      behavior: "smooth"
    }); */
  };

  render() {
    if (window.innerWidth > 1120) {
      return (
        <div
          style={{
            position: "sticky",
            top: "0",
            transition: ".1s",
            zIndex: "10",
          }}
        >
          <div id="title-bar">
            <Link to="/">
              <Logo />
            </Link>
            <div id="title-icon-row">
              <a
                href="https://www.facebook.com/dziedzictwopodlasia/"
                target="_blank"
                rel="noopener noreferrer"
                className="tb-icon-link"
              >
                <FacebookLogo />
              </a>
              <a
                href="instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="tb-icon-link"
              >
                <InstagramLogo />
              </a>
              <a
                href="https://www.youtube.com/channel/UC7w1ox6Zbd6jDSSl4D6Q4Yw"
                target="_blank"
                rel="noopener noreferrer"
                className="tb-icon-link"
              >
                <YoutubeLogo />
              </a>
              <a
                href="https://podlasie.org/wesprzyj-nas/"
                target="_blank"
                rel="noopener noreferrer"
                className="tb-icon-link"
              >
                <ProcentIcon />
              </a>
            </div>
          </div>
          <div id="menu">
            <Link className="menu-tile" to="/aktualnosci">
              <div className="menu-tile-title">AKTUALNOŚCI</div>
            </Link>
            <div className="menu-tile">
              <div className="menu-tile-title">HISTORIA</div>
              <div className="menu-tile-elements">
                <Link className="menu-tile-elements-title" to="/historia">
                  HISTORIA BUDOWNICTWA
                </Link>
                <Link className="menu-tile-elements-title" to="/slownik">
                  SŁOWNIK TERMINÓW ARCHITEKTONICZNYCH
                </Link>
              </div>
            </div>
            <Link className="menu-tile" to="/mapa">
              <div className="menu-tile-title">MAPA</div>
            </Link>
            <Link className="menu-tile" to="/obiekty">
              <div className="menu-tile-title">OBIEKTY</div>
            </Link>
            <div className="menu-tile">
              <div className="menu-tile-title">DLA INWESTORÓW</div>
              <div className="menu-tile-elements">
                <Link
                  className="menu-tile-elements-title"
                  to="/katalog-zdobien"
                >
                  KATALOG ZDOBIEŃ
                </Link>
                <Link className="menu-tile-elements-title" to="/jak-remontowac">
                  JAK REMONTOWAĆ, ABY NIE ZEPSUĆ
                </Link>
                <Link className="menu-tile-elements-title" to="/projekt">
                  ZOSTAŃ ARCHITEKTEM SWOJEGO DOMU
                </Link>
                <Link className="menu-tile-elements-title" to="/wykonawcy">
                  WYKONAWCY I RZEMIEŚLNICY
                </Link>
              </div>
            </div>
            <div className="menu-tile">
              <div className="menu-tile-title">DLA TURYSTÓW</div>
              <div className="menu-tile-elements">
                <Link className="menu-tile-elements-title" to="/szlaki">
                  SZLAKI
                </Link>
                <Link
                  className="menu-tile-elements-title"
                  to="/obiekty/skanseny"
                >
                  SKANSENY I ZAGRODY
                </Link>
                <Link className="menu-tile-elements-title" to="/obiekty/zwiedz">
                  ODWIEDŹ / ZWIEDŹ
                </Link>
                <Link
                  className="menu-tile-elements-title"
                  to="/obiekty/wojewodzka-ewidencja-zabytkow"
                >
                  ZABYTKOWE WSIE
                </Link>
                <Link
                  className="menu-tile-elements-title"
                  to="/obiekty/restauracja"
                >
                  TUTAJ DOBRZE ZJESZ
                </Link>
              </div>
            </div>
            <button className="menu-tile" onClick={this.toggleKontakt}>
              <div className="menu-tile-title">KONTAKT</div>
              <div className="menu-tile-elements"></div>
            </button>
          </div>
          <div
            id="tb-kontakt-body"
            style={{
              opacity: this.state.showKontakt ? "1" : "0",
              pointerEvents: this.state.showKontakt ? "all" : "none",
            }}
          >
            <div id="tb-kontakt-body-title">Kontakt</div>
            <button
              id="tb-kontakt-body-close-button"
              onClick={this.toggleKontakt}
              title="Zamknij"
            ></button>
            <div className="footer-column-line">
              <PhoneIcon />
              <div>602 430 772</div>
            </div>
            <div className="footer-column-line">
              <MailIcon />
              <div>sdp@podlasie.org</div>
            </div>
            <div className="footer-column-line">
              <MarkerIcon />
              <div>
                <div>Puchły 62</div>
                <div>17-210 Narew</div>
              </div>
            </div>
            <div id="footer-social-media">
              <a
                href="https://www.facebook.com/dziedzictwopodlasia/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookLogo style={{ fill: "var(--primary-color)" }} />
              </a>
              <a href="instagram.com" target="_blank" rel="noopener noreferrer">
                <InstagramLogo style={{ fill: "var(--primary-color)" }} />
              </a>
              <a
                href="https://www.youtube.com/channel/UC7w1ox6Zbd6jDSSl4D6Q4Yw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YoutubeLogo style={{ fill: "var(--primary-color)" }} />
              </a>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ position: "sticky", top: "0", zIndex: "10" }}>
          <div id="mobile-top-bar">
            <Link
              to="/"
              onClick={() => {
                if (this.state.menuOpen) {
                  this.setState({ menuOpen: false });
                  document.getElementsByTagName("body")[0].style.overflow = "";
                }
              }}
            >
              <Logo />
            </Link>
            <button
              id="mtb-menu-button"
              onClick={this.toggleMenu}
              title={this.state.menuOpen ? "Zamknij" : "Otwórz"}
            >
              <div
                style={{
                  transform: this.state.menuOpen ? "rotate(-45deg)" : "",
                  transformOrigin: "right",
                }}
              ></div>
              <div
                style={{
                  opacity: this.state.menuOpen ? "0" : "1",
                }}
              ></div>
              <div
                style={{
                  transform: this.state.menuOpen ? "rotate(45deg)" : "",
                  transformOrigin: "right",
                }}
              ></div>
            </button>
          </div>
          <div
            id="mtb-menu-list"
            style={{
              transform: this.state.menuOpen
                ? "translate(0, 0)"
                : "translate(0, calc(-100% - 52px))",
            }}
          >
            <Link
              className="menu-tile"
              to="/aktualnosci"
              onClick={this.toggleMenu}
            >
              <div className="menu-tile-title">AKTUALNOŚCI</div>
            </Link>
            <div className="menu-tile">
              <div className="menu-tile-title">HISTORIA</div>
              <div className="mtb-tile-elements">
                <Link
                  className="mtb-tile-elements-title"
                  to="/historia"
                  onClick={this.toggleMenu}
                >
                  HISTORIA BUDOWNICTWA
                </Link>
                <Link
                  className="mtb-tile-elements-title"
                  to="/slownik"
                  onClick={this.toggleMenu}
                >
                  SŁOWNIK
                </Link>
              </div>
            </div>
            <Link className="menu-tile" to="/mapa" onClick={this.toggleMenu}>
              <div className="menu-tile-title">MAPA</div>
            </Link>
            <Link className="menu-tile" to="/obiekty" onClick={this.toggleMenu}>
              <div className="menu-tile-title">OBIEKTY</div>
            </Link>
            <div className="menu-tile">
              <div className="menu-tile-title">DLA INWESTORÓW</div>
              <div className="mtb-tile-elements">
                <Link
                  className="mtb-tile-elements-title"
                  to="/katalog-zdobien"
                  onClick={this.toggleMenu}
                >
                  KATALOG ZDOBIEŃ
                </Link>
                <Link
                  className="mtb-tile-elements-title"
                  to="/jak-remontowac"
                  onClick={this.toggleMenu}
                >
                  JAK REMONTOWAĆ, ABY NIE ZEPSUĆ
                </Link>
                <Link
                  className="mtb-tile-elements-title"
                  to="/projekt"
                  onClick={this.toggleMenu}
                >
                  ZOSTAŃ ARCHITEKTEM SWOJEGO DOMU
                </Link>
                <Link
                  className="mtb-tile-elements-title"
                  to="/wykonawcy"
                  onClick={this.toggleMenu}
                >
                  WYKONAWCY I RZEMIEŚLNICY
                </Link>
              </div>
            </div>
            <div className="menu-tile">
              <div className="menu-tile-title">DLA TURYSTÓW</div>
              <div className="mtb-tile-elements">
                <Link
                  className="mtb-tile-elements-title"
                  to="/szlaki"
                  onClick={this.toggleMenu}
                >
                  SZLAKI
                </Link>
                <Link
                  className="mtb-tile-elements-title"
                  to="/skanseny-i-zagrody"
                  onClick={this.toggleMenu}
                >
                  SKANSENY I ZAGRODY
                </Link>
                <Link
                  className="mtb-tile-elements-title"
                  to="/odwiedz-zwiedz"
                  onClick={this.toggleMenu}
                >
                  ODWIEDŹ / ZWIEDŹ
                </Link>
                <Link
                  className="mtb-tile-elements-title"
                  to="/zabytkowe-wsie"
                  onClick={this.toggleMenu}
                >
                  ZABYTKOWE WSIE
                </Link>
                <Link
                  className="mtb-tile-elements-title"
                  to="/restauracje"
                  onClick={this.toggleMenu}
                >
                  TUTAJ DOBRZE ZJESZ
                </Link>
              </div>
            </div>
            <button
              className="menu-tile"
              onClick={() => {
                this.toggleMenu();
                this.toggleKontakt();
              }}
            >
              <div className="menu-tile-title">KONTAKT</div>
              <div className="menu-tile-elements"></div>
            </button>
          </div>
          <div
            id="mtb-background"
            onClick={this.toggleMenu}
            style={{
              opacity: this.state.menuOpen ? "1" : "0",
              pointerEvents: this.state.menuOpen ? "all" : "none",
            }}
          ></div>
          <div
            id="tb-kontakt-body"
            style={{
              opacity: this.state.showKontakt ? "1" : "0",
              pointerEvents: this.state.showKontakt ? "all" : "none",
              right: "16px",
              top: "66px",
            }}
          >
            <div id="tb-kontakt-body-title">Kontakt</div>
            <button
              id="tb-kontakt-body-close-button"
              onClick={this.toggleKontakt}
              title="Zamknij"
            ></button>
            <div className="footer-column-line">
              <PhoneIcon />
              <div>602 430 772</div>
            </div>
            <div className="footer-column-line">
              <MailIcon />
              <div>sdp@podlasie.org</div>
            </div>
            <div className="footer-column-line">
              <MarkerIcon />
              <div>
                <div>Puchły 62</div>
                <div>17-210 Narew</div>
              </div>
            </div>
            <div id="footer-social-media">
              <a
                href="https://www.facebook.com/dziedzictwopodlasia/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookLogo style={{ fill: "var(--primary-color)" }} />
              </a>
              <a href="instagram.com" target="_blank" rel="noopener noreferrer">
                <InstagramLogo style={{ fill: "var(--primary-color)" }} />
              </a>
              <a
                href="https://www.youtube.com/channel/UC7w1ox6Zbd6jDSSl4D6Q4Yw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YoutubeLogo style={{ fill: "var(--primary-color)" }} />
              </a>
            </div>
          </div>
        </div>
      );
    }
  }
}
